import { ColorPicker, Form, Modal } from 'antd'
import { useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'

const STATUS_SUCCESS = 200

export function ModalCreateInsuranceCompany({ isOpen, onClose, reloadInsurancies }) {
    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const onSubmit = async insurance_data => {
        setLoading(true)
        try {
            const response = await api.insurancies.create(insurance_data)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadInsurancies()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={displayMessage('INSURANCE_COMPANY_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('NAME')}</div>

                    <Form.Item name="name" rules={[{ required: true, message: displayMessage('MANDATORY_FIELD') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('NAME')} className={'w-full'} />
                    </Form.Item>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('COLOR')}</div>

                    <Form.Item name="color" rules={[{ required: true, message: displayMessage('COLOR') }]} className="mb-4">
                        <ColorPicker defaultValue={'#24787b'}></ColorPicker>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}
