import { displayMessage } from '../../../helpers/messages'
import EReportRiskStatus from '../../../enums/EReportRiskStatus'
import { Tooltip } from 'antd'

export const report_risk_grid_columns = handleOnDownload => [
    {
        field: 'sequence',
        headerName: displayMessage('RECOMMENDATION_NUMBER'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.sequence) return row.sequence
            return displayMessage('EMPTY_01')
        },
        renderCell: params => {
            const row = params.api.getRow(params.id)
            const text = row.sequence ?? displayMessage('EMPTY_01')
            const conflict = row.status === EReportRiskStatus.CONFLICT.code
            return (
                <div className="flex gap-1">
                    <div className="w-[20px]">{conflict ? '⚠️' : ''}</div>
                    {text}
                </div>
            )
        },
    },
    {
        field: 'Risk',
        headerName: displayMessage('RISK'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Risk) return row.Risk.description
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.Risk) return row.Risk.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'Recommendation',
        headerName: displayMessage('RECOMMENDATION'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Risk) return row.Risk.Recommendation.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'priority_level',
        headerName: displayMessage('PRIORITY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.PriorityLevel) return row.PriorityLevel.name
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.PriorityLevel) return row.PriorityLevel.name
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'affected_pillar',
        headerName: displayMessage('AFFECTED_PILLAR'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.AffectedPillar) return row.AffectedPillar.title
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.AffectedPillar) return row.AffectedPillar.title
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'insurance',
        headerName: displayMessage('INSURANCE_COMPANY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.InsuranceCompany) return row.InsuranceCompany.title
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.InsuranceCompany) return row.InsuranceCompany?.name
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'frequency',
        headerName: displayMessage('FREQUENCY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Frequency) return row.Frequency.description
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.Frequency) return row.Frequency.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'control_mesure',
        headerName: displayMessage('CONTROL_MESURE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.ControlMesure) return row.ControlMesure.description
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.ControlMesure) return row.ControlMesure.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'detection',
        headerName: displayMessage('DETECTION'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Detection) return row.Detection.description
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.Detection) return row.Detection.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'severity',
        headerName: displayMessage('SEVERITY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.Severity) return row.Severity.description
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.Severity) return row.Severity.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'coverage_scale',
        headerName: displayMessage('COVERAGE_SCALE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.CoverageScale) return row.CoverageScale.description
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.CoverageScale) return row.CoverageScale.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'attachments',
        headerName: displayMessage('ATTACHMENTS'),
        type: 'string',
        width: 450,
        filterable: false,
        renderCell: params => {
            const row = params.api.getRow(params.id)
            const attachment = row?.Attachment

            if (attachment?.file) {
                return (
                    <Tooltip
                        title={
                            <a
                                href={attachment.url}
                                download
                                style={{ display: 'block', marginBottom: '4px' }}
                                onClick={() => handleOnDownload(attachment)}
                            >
                                {attachment.name}
                            </a>
                        }
                        overlayStyle={{ maxWidth: 500 }}
                        overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                    >
                        <div className="flex flex-row">
                            <a
                                href={attachment.url}
                                download
                                style={{ display: 'block', marginBottom: '4px' }}
                                onClick={() => handleOnDownload(attachment)}
                            >
                                {attachment.name}
                            </a>
                        </div>
                    </Tooltip>
                )
            }
            return displayMessage('EMPTY_01')
        },
    },
]
