import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import { Box } from '@mui/material'

export const insurance_companies_grid_columns = [
    {
        field: 'name',
        headerName: displayMessage('NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.name) return row.name
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row) return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },
    {
        field: 'color',
        headerName: displayMessage('COLOR'),
        type: 'date',
        width: 150,
        renderCell: params => {
            const row = params.api.getRow(params.id);
            const color = row.color || '#FFFFFF';
            return (
                <Box
                    sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: color,
                        borderRadius: 0.5,
                        border: '1px solid #ddd',
                    }}
                />
            );
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
