import { Card, Checkbox, Collapse, Divider, Form, Input, List, Space, Tooltip, Upload } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { SL_ClipLoader } from '../../../../components/loading/ClipLoader'
import api from '../../../../services/api'
import { displayMessage } from '../../../../helpers/messages'
import { SL_Select } from '../../../../components/selects/Select'
import { SL_Input } from '../../../../components/input/Input'
import { SL_Button } from '../../../../components/buttons/Button'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../../enums/EAlertTypes'
import { formatCnae, formatCnpj } from '../../../../helpers/helper'
import { CloseCircleOutlined, InfoCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SL_Divider } from '../../../../components/divider/Divider'
import { ModalCreateClientAddress } from '../../../listClients/components/ModalCreateClientAddress'
import { MultiSelect } from '../../../../components/selects/MultiSelect'
import EActivationStatus from '../../../../enums/EActivationStatus'
import { ModalCreateClientWorkSector } from '../../../listClients/components/ModalCreateClientSector'

const { Panel } = Collapse

const GENERAL_DATA_KEY = 1
const PARTICIPANTS_KEY = 2
const RISKS_IDENTIFICATION_KEY = 3
const STRENGTHS_KEY = 4
const PROBABILITY_X_GRAVITY_KEY = 5
const RISK_COMPLEMENT_DATA_KEY = 6

const STATUS_SUCCESS = 200
const maxRequesterForFilter = 10
const charactersLimitToFetch = 1

export function CreateReport({ changeTab, reloadReports }) {
    const { showAlert, setShowLoading, mobile_mode } = useContext(GeneralContext)

    const reportUserFormRef = useRef()
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)

    const [reportConfigs, setReportConfigs] = useState([])
    const [selectedReportConfig, setSelectedReportConfig] = useState(null)

    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)

    const [selectedAddress, setSelectedAddress] = useState(null)
    const [selectedWorkSector, setSelectedWorkSector] = useState(null)

    const [strengths, setStrengths] = useState([])
    const [selectedStrengths, setSelectedStrengths] = useState([])

    const [riskTypes, setRiskTypes] = useState([])
    const [selectedRiskType, setSelectedRiskType] = useState(null)

    const [riskActivities, setRiskActivities] = useState([])
    const [selectedRiskActivity, setSelectedRiskActivity] = useState(null)

    const [riskKinds, setRiskKinds] = useState([])
    const [selectedRiskKind, setSelectedRiskKind] = useState(null)

    const [risks, setRisks] = useState([])
    const [selectedRisk, setSelectedRisk] = useState(null)

    const [risksWithRecommendationsAndNormatives, setRisksWithRecommendationsAndNormatives] = useState([])

    const [reportUsers, setReportUsers] = useState([])

    const [controlMesures, setControlMesures] = useState([])
    const [selectedControlMesure, setSelectedControlMesure] = useState(null)

    const [frequencies, setFrequencies] = useState([])
    const [selectedFrequency, setSelectedFrequency] = useState(null)

    const [insurancies, setInsurancies] = useState([])
    const [selectedInsurance, setSelectedInsurance] = useState(null)

    const [detections, setDetections] = useState([])
    const [selectedDetection, setSelectedDetection] = useState(null)

    const [severities, setSeverities] = useState([])
    const [selectedSeverity, setSelectedSeverity] = useState(null)

    const [coverageScales, setCoverageScales] = useState([])
    const [selectedCoverageScale, setSelectedCoverageScale] = useState(null)

    const [priorityLevels, setPriorityLevels] = useState([])
    const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null)
    const [priorityLevelResult, setPriorityLevelResult] = useState(null)

    const [affectedPillars, setAffectedPillars] = useState([])
    const [selectedAffectedPillar, setSelectedAffectedPillar] = useState(null)

    const [reportRiskImage, setReportRiskImage] = useState([])
    const [imageDescription, setImageDescription] = useState(null)

    const [location, setLocation] = useState(null)

    const [observations, setObservations] = useState(null)

    const [reportIsValid, setReportIsValid] = useState(false)
    const [modalCreateClientAddressIsOpen, setModalCreateClientAddressIsOpen] = useState(false)
    const [modalCreateWorkSectorIsOpen, setModalCreateWorkSectorIsOpen] = useState(false)
    const [selectedClientUsersId, setSelectedClientUsersId] = useState([])

    const [workSectors, setWorkSectors] = useState(null)

    const [clientId, setClientId] = useState(null)

    useEffect(() => {
        loadAllData()
    }, [])

    useEffect(() => {
        if (clientId) loadWorkSectors()
    }, [clientId])

    const loadAllData = async () => {
        setLoading(true)

        await Promise.all([
            loadClients(),
            loadStrengths(),
            loadControlMesures(),
            loadFrequencies(),
            loadInsurancies(),
            loadDetections(),
            loadSeverities(),
            loadCoverageScales(),
            loadPriorityLevels(),
            loadAffectedPillars(),
            loadRiskTypes(),
            loadReportConfigs(),
        ])

        setLoading(false)
    }

    const loadWorkSectors = async params => {
        try {
            const { data } = await api.workSectors.list({ client_id: clientId, ...params })

            setWorkSectors(data)
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const loadClients = async () => {
        try {
            const { data } = await api.client.list()

            setClients(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadStrengths = async () => {
        try {
            const { data } = await api.strengths.list()

            setStrengths(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadControlMesures = async () => {
        try {
            const { data } = await api.controlMesures.list()

            setControlMesures(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFrequencies = async () => {
        try {
            const { data } = await api.frequencies.list()

            setFrequencies(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadInsurancies = async () => {
        try {
            const { data } = await api.insurancies.list()

            setInsurancies(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadDetections = async () => {
        try {
            const { data } = await api.detections.list()

            setDetections(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadSeverities = async () => {
        try {
            const { data } = await api.severities.list()

            setSeverities(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadCoverageScales = async () => {
        try {
            const { data } = await api.coverageScales.list()

            setCoverageScales(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadPriorityLevels = async () => {
        try {
            const { data } = await api.priorityLevels.list()

            setPriorityLevels(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadAffectedPillars = async () => {
        try {
            const { data } = await api.affectedPillars.list()

            setAffectedPillars(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadReportConfigs = async () => {
        try {
            const { data } = await api.reportConfigs.list()

            setReportConfigs(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedReportConfig = report_config_id => {
        if (!report_config_id) {
            setSelectedReportConfig(null)
        }

        setSelectedReportConfig(report_config_id)
    }

    const handleSelectedClient = async selected_id => {
        if (!selected_id) return

        setClientId(selected_id)

        setSelectedAddress(null)

        setLoading(true)
        try {
            const { data } = await api.client.find(selected_id)

            if (data.Addresses.length == 0) {
                setReportIsValid(false)
                showAlert(displayMessage('ERROR_INFO_CLIENT_DOESNT_HAVE_ADDRESS'), EAlertTypes.ERROR)
            } else {
                setReportIsValid(true)
            }

            setSelectedClient(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSelectedWorkSector = work_sector => {
        if (!work_sector) {
            setSelectedWorkSector(null)
            return
        }
        setSelectedWorkSector(work_sector)
    }

    const handleSelectedRiskType = async risk_type_id => {
        if (!risk_type_id) {
            setSelectedRiskType(null)
            return
        }

        setSelectedRiskType(risk_type_id)

        setLoading(true)
        try {
            const { data } = await api.riskActivities.list({ risk_type_id })

            setRiskActivities(data)
            setSelectedRiskActivity(undefined)
            setRiskKinds([])
            setSelectedRiskKind(undefined)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSelectedRiskActivity = async risk_activity_id => {
        if (!risk_activity_id) {
            setSelectedRiskActivity(null)
            return
        }

        setSelectedRiskActivity(risk_activity_id)

        setLoading(true)
        try {
            const { data } = await api.riskKinds.list({ risk_activity_id })

            setRiskKinds(data)
            setSelectedRiskKind(undefined)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSelectedRiskKind = async risk_kind_id => {
        if (!risk_kind_id) {
            setSelectedRiskKind(null)
            return
        }

        setSelectedRiskKind(risk_kind_id)

        setLoading(true)
        try {
            const { data } = await api.risks.list({ risk_kind_id })

            setRisks(data)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleStrengthsChange = value => {
        setSelectedStrengths(value)
    }

    const handleNewReportUser = values => {
        const new_report_user = { ...values, temporary_id: crypto.randomUUID() }
        setReportUsers(prev => [...prev, new_report_user])
        form.resetFields()
    }

    const handleOnRemoveReportUser = temporary_id => {
        if (!temporary_id) return

        const newReportUsers = reportUsers.filter(e => e.temporary_id !== temporary_id)

        setReportUsers(newReportUsers)
    }

    const handleOnRemoveStrenght = strenght_id => {
        const newStrenghts = selectedStrengths.filter(e => e !== strenght_id)

        setSelectedStrengths(newStrenghts)
    }

    const handleOnAddressSelected = address_id => {
        if (address_id == selectedAddress) {
            setSelectedAddress(null)
            return
        }

        setSelectedAddress(address_id)
    }

    const handleSelectedRisk = async risk_id => {
        if (!risk_id) {
            setSelectedRisk(null)
            return
        }

        try {
            const { data } = await api.risks.find(risk_id)

            setSelectedRisk(data)
        } catch (error) {
            console.log(error)
        }
    }

    const validateProbabilityXGravityDataForCalculation = () => {
        if (!selectedControlMesure) {
            // showAlert(displayMessage('DISPLAY_CONTROL_MESURE'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedDetection) {
            // showAlert(displayMessage('DISPLAY_DETECTION'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedCoverageScale) {
            // showAlert(displayMessage('DISPLAY_COVERAGE_SCALE'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedFrequency) {
            // showAlert(displayMessage('DISPLAY_FREQUENCY', EAlertTypes.ERROR))
            return false
        }

        if (!selectedSeverity) {
            // showAlert(displayMessage('DISPLAY_SEVERITY'), EAlertTypes.ERROR)
            return false
        }

        return true
    }

    const handleOnCalculatePriorityLevel = async () => {
        setShowLoading(true)
        try {
            const dataIsValidToCalculate = validateProbabilityXGravityDataForCalculation()

            if (dataIsValidToCalculate) {
                const params = {
                    control_mesure_id: selectedControlMesure,
                    detection_id: selectedDetection,
                    coverage_scale_id: selectedCoverageScale,
                    frequency_id: selectedFrequency,
                    severity_id: selectedSeverity,
                }

                const { data } = await api.priorityLevels.calculate(params)

                const { priority_level_entity, priority_level_result } = data

                setSelectedPriorityLevel(priority_level_entity)
                setPriorityLevelResult(priority_level_result)

                return { priority_level_entity, priority_level_result }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const calculatePriorityLevelWhenFinishRisk = async () => {
        try {
            if (selectedControlMesure && selectedDetection && selectedCoverageScale && selectedFrequency && selectedSeverity) {
                const params = {
                    control_mesure_id: selectedControlMesure,
                    detection_id: selectedDetection,
                    coverage_scale_id: selectedCoverageScale,
                    frequency_id: selectedFrequency,
                    severity_id: selectedSeverity,
                }

                const { data } = await api.priorityLevels.calculate(params)

                const { priority_level_entity, priority_level_result } = data

                setSelectedPriorityLevel(priority_level_entity)
                setPriorityLevelResult(priority_level_result)

                return { priority_level_entity, priority_level_result }
            }
            return {}
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnRiskIsFinished = async () => {
        try {
            const { priority_level_entity, priority_level_result } = await calculatePriorityLevelWhenFinishRisk()
            const formatNames = names => {
                if (names.length === 2) {
                    return names.join(' e ')
                } else if (names.length > 2) {
                    return names.slice(0, -1).join(', ') + ' e ' + names[names.length - 1]
                } else {
                    return names[0] || null
                }
            }
            const selectedRiskWithData = {
                temporary_id: crypto.randomUUID(),
                ...selectedRisk,
                control_mesure_id: selectedControlMesure,
                frequency_id: selectedFrequency,
                detection_id: selectedDetection,
                severity_id: selectedSeverity,
                coverage_scale_id: selectedCoverageScale,
                priority_level: (selectedPriorityLevel?.name || priority_level_entity?.name) ?? null,
                priority_level_id: (selectedPriorityLevel?.id || priority_level_entity?.id) ?? null,
                affected_pillar_id: selectedAffectedPillar,
                work_sector_id:
                    selectedClient?.WorkSectors.find(e => e.id == selectedWorkSector)?.id ?? workSectors.find(e => e.id == selectedWorkSector)?.id,
                work_sector:
                    selectedClient?.WorkSectors.find(e => e.id == selectedWorkSector)?.name ??
                    workSectors.find(e => e.id == selectedWorkSector)?.name,
                location,
                observations,
                priority_level_level: priorityLevelResult ?? priority_level_result,
                image: reportRiskImage || null,
                imageDescription,
                client_user: formatNames(selectedClientUsersId.map(e => e.label)),
                clientUsersId: selectedClientUsersId,
                risk_id: selectedRisk?.id,
                insurance_companie_id: selectedInsurance
            }

            delete selectedRiskWithData?.id

            setRisksWithRecommendationsAndNormatives(prev => [...prev, selectedRiskWithData])

            setReportRiskImage([])
            setSelectedWorkSector(null)
            setSelectedRisk(null)
            setSelectedRiskKind(null)
            setSelectedRiskActivity(null)
            setSelectedRiskType(null)
            setSelectedControlMesure(null)
            setSelectedFrequency(null)
            setSelectedDetection(null)
            setSelectedSeverity(null)
            setSelectedCoverageScale(null)
            setSelectedPriorityLevel(null)
            setSelectedAffectedPillar(null)
            setLocation(null)
            setObservations(null)
            setPriorityLevelResult(null)
            setImageDescription(null)
            setReportIsValid(true)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnRemoveSelectedRisk = temporary_id => {
        if (!temporary_id) return

        const new_selected_risks = risksWithRecommendationsAndNormatives.filter(e => e.temporary_id !== temporary_id)

        setRisksWithRecommendationsAndNormatives(new_selected_risks)
    }

    const handleSelectControlMesure = value => {
        setSelectedControlMesure(value)
    }

    const handleSelectFrequency = value => {
        setSelectedFrequency(value)
    }

    const handleSelectInsurance = value => {
        setSelectedInsurance(value)
    }

    const handleSelectDetection = value => {
        setSelectedDetection(value)
    }

    const handleSelectSeverity = value => {
        setSelectedSeverity(value)
    }

    const handleSelectCoverageScale = value => {
        setSelectedCoverageScale(value)
    }

    const handleSelectAffectedPillar = value => {
        setSelectedAffectedPillar(value)
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setReportRiskImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const sendReport = async () => {
        const isValid = validateReport()

        if (!isValid) return

        setLoading(true)
        try {
            const formData = new FormData()

            const reportObject = {
                selectedReportConfig,
                selectedClient,
                selectedAddress,
                selectedStrengths,
                risksWithRecommendationsAndNormatives,
                reportUsers,
            }
            formData.append('report_object', JSON.stringify(reportObject))

            for (let i = 0; i < risksWithRecommendationsAndNormatives.length; i++) {
                const risk = risksWithRecommendationsAndNormatives[i]

                if (risk.image.length > 0) {
                    const file = new File([risk.image[0].originFileObj], risk.image[0].name, { type: risk.image[0].type })
                    formData.append(`attachments_${risk.temporary_id}`, file)
                }
            }
            const response = await api.reportRisks.create(formData)

            if (response.status == STATUS_SUCCESS) {
                setSelectedClient(null)
                setSelectedAddress(null)
                setRisksWithRecommendationsAndNormatives([])
                setReportUsers([])
                setSelectedRiskType(undefined)
                setSelectedRiskActivity(undefined)
                setSelectedRiskKind(undefined)
                setSelectedRisk(undefined)
                setSelectedControlMesure(null)
                setSelectedFrequency(null)
                setSelectedDetection(null)
                setSelectedSeverity(null)
                setSelectedCoverageScale(null)
                setSelectedPriorityLevel(null)
                setSelectedAffectedPillar(null)
                setLocation(null)
                setSelectedClientUsersId([])
                setImageDescription(null)
                await reloadReports()
                changeTab()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const validateReport = () => {
        if (!selectedReportConfig) {
            showAlert(displayMessage('ERROR_NO_REPORT_CONFIG'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedAddress) {
            showAlert(displayMessage('ERROR_NO_ADDRESS'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedClient) {
            showAlert(displayMessage('ERROR_NO_CLIENT'), EAlertTypes.ERROR)
            return false
        }

        return true
    }

    const mountClients = async e => {
        try {
            const { data } = await api.client.listForSelectOptions({
                name: e,
                limit: maxRequesterForFilter,
                statusActivation: EActivationStatus.ATIVO.code,
            })

            return data
        } catch (error) {
            console.log(error)
        }
    }

    const mountClientUsers = async e => {
        try {
            const { data } = await api.clientUsers.listForSelectOptions({
                name: e,
                limit: maxRequesterForFilter,
                statusActivation: true,
                work_sector_id: selectedWorkSector,
            })

            return data
        } catch (error) {
            console.log(error)
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    return (
        <>
            {loading && (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-700 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            )}
            <Collapse ghost accordion size="large">
                <Panel header={displayMessage('GENERAL_DATA')} key={GENERAL_DATA_KEY}>
                    <div className="flex flex-row w-full gap-5">
                        <div className="flex flex-col w-full">
                            <p className="mb-2">{displayMessage('SELECT_ONE_REPORT_CONFIG')}</p>
                            <SL_Select
                                maxTagCount="responsive"
                                className="mb-2 w-full h-[28px] rounded-sm"
                                listItemHeight={100}
                                title={displayMessage('REPORT_CONFIGS')}
                                placeholder={selectedReportConfig?.title || displayMessage('REPORT_CONFIGS')}
                                onChange={handleSelectedReportConfig}
                                value={selectedReportConfig?.title}
                                options={reportConfigs.map(e => ({ label: e.title, value: e.id }))}
                                allowClear
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <p className="mb-2">{displayMessage('SELECT_ONE_CLIENT')}</p>
                            <MultiSelect
                                charactersLimitToFetch={charactersLimitToFetch}
                                maxTagCount="responsive"
                                className="mb-2 w-full h-[28px] rounded-sm"
                                mode="single"
                                allowClear
                                fetchOptions={async e => mountClients(e)}
                                placeholder={selectedClient?.name || displayMessage('CLIENTS')}
                                onChange={e => handleSelectedClient(e?.value)}
                            />
                        </div>
                    </div>
                    {selectedClient != null && (
                        <div className="w-full flex flex-row gap-5">
                            <Card title={'Informações da empresa'} className="w-1/2 bg-sl-gray-50 mt-5">
                                <div>
                                    {displayMessage('CLIENT_NAME')}: {selectedClient?.name}
                                </div>
                                <div>
                                    {displayMessage('CLIENT_CNPJ')}: {selectedClient?.cnpj && formatCnpj(selectedClient?.cnpj)}
                                </div>
                                <div>
                                    {displayMessage('CLIENT_NUMBER_CNAE')}: {selectedClient?.cnae_number && formatCnae(selectedClient?.cnae_number)}
                                </div>
                                <div>
                                    {displayMessage('CLIENT_CNAE_DESCRIPTION')}: {selectedClient?.cnae}
                                </div>
                            </Card>
                            <Card title={'Endereços da empresa'} className="w-1/2 bg-sl-gray-50 mt-5">
                                <div className="text-zinc-700 mb-5">{displayMessage('SELECT_ONE_ADDRESS_TO_REPORT')}</div>
                                {selectedClient?.Addresses.map((item, index) => {
                                    const thisItemIsAlreadyInAnotherReport = item.already_used
                                    return mobile_mode ? (
                                        <>
                                            <div className="flex flex-row w-full" key={index}>
                                                <div
                                                    className={`flex flex-col ${mobile_mode && ' w-full text-center'} ${
                                                        thisItemIsAlreadyInAnotherReport && 'underline text-red-300'
                                                    }`}
                                                >
                                                    <div className="mr-2">
                                                        {item.state} - {item.city}
                                                    </div>
                                                    <div className="mr-2">
                                                        {item.street} - {item.neighbourhood} - {item.number}
                                                    </div>
                                                    <div style={{ alignSelf: 'center' }}>
                                                        <div>
                                                            {!thisItemIsAlreadyInAnotherReport && (
                                                                <Checkbox
                                                                    className={`ml-5`}
                                                                    style={{ alignSelf: 'center' }}
                                                                    disabled={thisItemIsAlreadyInAnotherReport}
                                                                    onChange={() => handleOnAddressSelected(item.id)}
                                                                    checked={selectedAddress == item.id ? true : false}
                                                                />
                                                            )}
                                                            {thisItemIsAlreadyInAnotherReport && (
                                                                <div className="ml-5">
                                                                    <Tooltip
                                                                        placement="top"
                                                                        title={displayMessage('ADDRESS_ALREADY_USED_IN_ANOTHER_REPORT')}
                                                                    >
                                                                        <InfoCircleOutlined
                                                                            style={{
                                                                                fontSize: '20px',
                                                                                color: thisItemIsAlreadyInAnotherReport ? 'red' : 'black',
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="flex flex-row w-full" key={index}>
                                            <div className={`flex flex-row ${thisItemIsAlreadyInAnotherReport && 'underline text-red-300'}`}>
                                                <div className="mr-2">{item.state} -</div>
                                                <div className="mr-2">{item.city} -</div>
                                                <div className="mr-2">{item.neighbourhood} -</div>
                                                <div className="mr-2">{item.street} -</div>
                                                <div>{item.number}</div>
                                                {!thisItemIsAlreadyInAnotherReport && (
                                                    <Checkbox
                                                        className={`ml-5`}
                                                        disabled={thisItemIsAlreadyInAnotherReport}
                                                        onChange={() => handleOnAddressSelected(item.id)}
                                                        checked={selectedAddress == item.id ? true : false}
                                                    />
                                                )}
                                                {thisItemIsAlreadyInAnotherReport && (
                                                    <div className="ml-5">
                                                        <Tooltip placement="top" title={displayMessage('ADDRESS_ALREADY_USED_IN_ANOTHER_REPORT')}>
                                                            <InfoCircleOutlined
                                                                style={{
                                                                    fontSize: '20px',
                                                                    color: thisItemIsAlreadyInAnotherReport ? 'red' : 'black',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="mt-3 flex flex-row">
                                    <div className="cursor-pointer mr-2" onClick={() => setModalCreateClientAddressIsOpen(true)}>
                                        <PlusCircleOutlined />
                                    </div>
                                    <div>{displayMessage('ADD_NEW_ADDRESS')}</div>
                                </div>
                                {modalCreateClientAddressIsOpen && (
                                    <ModalCreateClientAddress
                                        isOpen={modalCreateClientAddressIsOpen}
                                        onClose={setModalCreateClientAddressIsOpen}
                                        reloadClientAdressess={() => handleSelectedClient(selectedClient.id)}
                                        externClientId={selectedClient.id}
                                    />
                                )}
                            </Card>
                        </div>
                    )}
                </Panel>
                <Panel header={displayMessage('PARTICIPANTS')} key={PARTICIPANTS_KEY}>
                    <div className="mb-3">{displayMessage('CREATE_NEW_REPORT_USERS')}</div>
                    <Form onFinish={handleNewReportUser} className="w-full" layout="inline" requiredMark={false} form={form} ref={reportUserFormRef}>
                        <div className={`flex ${mobile_mode ? 'flex-col gap-0' : 'flex-row gap-5'} w-full`}>
                            <div className={`flex flex-col gap-1 ${mobile_mode ? 'w-full' : 'w-1/2'}`}>
                                <div className="">{displayMessage('FIRST_NAME')}</div>

                                <Form.Item name="name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4 w-full">
                                    <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full'} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-col gap-1 w-full">
                                <div className="">{displayMessage('WORK_DESCRIPTION')}</div>

                                <Form.Item
                                    name="work_description"
                                    rules={[{ required: true, message: displayMessage('DISPLAY_WORK_DESCRIPTION') }]}
                                    className="mb-4 w-full"
                                >
                                    <SL_Input placeholder={displayMessage('WORK_DESCRIPTION')} className={'w-full'} />
                                </Form.Item>
                            </div>

                            <div className="flex justify-center flex-col mt-2">
                                <SL_Button onClick={() => reportUserFormRef.current.submit()} className={'w-full'}>
                                    {displayMessage('ADD')}
                                </SL_Button>
                            </div>
                        </div>
                    </Form>
                    <div className="mt-3">{displayMessage('PARTICIPANTS')}</div>
                    <div className="flex flex-col w-full justify-start">
                        {reportUsers.length > 0 && (
                            <List
                                size="large"
                                bordered
                                dataSource={reportUsers}
                                renderItem={item => (
                                    <List.Item>
                                        <div className="w-full flex flex-row mt-1">
                                            <div>
                                                {item?.name} | {item?.work_description}
                                            </div>
                                            <div className="ml-5 cursor-pointer" onClick={() => handleOnRemoveReportUser(item.temporary_id)}>
                                                <CloseCircleOutlined style={{ fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        )}
                    </div>
                </Panel>
                <Panel header={displayMessage('RISKS_IDENTIFICATION')} key={RISKS_IDENTIFICATION_KEY}>
                    <div className="flex flex-col">
                        <div className={`flex flex-wrap gap-3 border-solid border-sl-gray-200 p-5`}>
                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_TYPE')}</p>
                                <SL_Select
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    mode="single"
                                    listItemHeight={100}
                                    title={displayMessage('RISK_TYPES')}
                                    placeholder={displayMessage('RISK_TYPES')}
                                    filterOption={filterOption}
                                    onChange={handleSelectedRiskType}
                                    optionFilterProp="children"
                                    options={riskTypes.map(e => ({ label: e.name, value: e.id }))}
                                    allowClear
                                    showSearch
                                    value={selectedRiskType ?? undefined}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_ACTIVITY')}</p>
                                <SL_Select
                                    disabled={selectedRiskType == null}
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    mode="single"
                                    listItemHeight={100}
                                    title={displayMessage('RISK_ACTIVITIES')}
                                    placeholder={displayMessage('RISK_ACTIVITIES')}
                                    filterOption={filterOption}
                                    onChange={handleSelectedRiskActivity}
                                    optionFilterProp="children"
                                    options={riskActivities.map(e => ({ label: e.name, value: e.id }))}
                                    allowClear
                                    showSearch
                                    value={selectedRiskActivity ?? undefined}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_KIND')}</p>
                                <SL_Select
                                    disabled={selectedRiskActivity == null}
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    mode="single"
                                    listItemHeight={100}
                                    title={displayMessage('RISK_KINDS')}
                                    placeholder={displayMessage('RISK_KINDS')}
                                    filterOption={filterOption}
                                    onChange={handleSelectedRiskKind}
                                    optionFilterProp="children"
                                    options={riskKinds.map(e => ({ label: e.name, value: e.id }))}
                                    allowClear
                                    showSearch
                                    value={selectedRiskKind ?? undefined}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK')}</p>
                                <SL_Select
                                    disabled={selectedRiskActivity == null}
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    mode="single"
                                    listItemHeight={100}
                                    title={displayMessage('RISKS')}
                                    placeholder={displayMessage('RISKS')}
                                    filterOption={filterOption}
                                    onChange={handleSelectedRisk}
                                    optionFilterProp="children"
                                    options={risks.map(e => ({ label: e.title, value: e.id }))}
                                    allowClear
                                    showSearch
                                    value={selectedRisk?.title ?? undefined}
                                />
                            </div>
                        </div>

                        {selectedRisk != null && (
                            <>
                                <div className="flex flex-col items-center justify-center">
                                    <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-5 mt-5`}>
                                        <Card className="w-full bg-sl-gray-50" title={displayMessage('DESCRIPTION_FOR_THIS_RISK')}>
                                            {selectedRisk?.title}
                                        </Card>
                                    </div>
                                    <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-5 mt-5`}>
                                        <Card className="w-full bg-sl-gray-50" title={displayMessage('RECOMMENDATION_FOR_THIS_RISK')}>
                                            {selectedRisk?.Recommendation?.description}
                                        </Card>
                                        <Card className="w-full bg-sl-gray-50 p-0" title={displayMessage('NORMATIVES')}>
                                            <List
                                                size="small"
                                                locale={{ emptyText: displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK') }}
                                                className="rounded p-0 m-0"
                                                itemLayout="horizontal"
                                                dataSource={selectedRisk?.Normatives}
                                                renderItem={item => (
                                                    <List.Item className="p-2">
                                                        <p>{item.description}</p>
                                                    </List.Item>
                                                )}
                                            />
                                        </Card>
                                    </div>
                                    <div className={`flex w-full mt-5`}>
                                        <Collapse ghost accordion size="large" className="w-full">
                                            <Panel
                                                header={displayMessage('PROBABILITY_X_GRAVITY')}
                                                key={PROBABILITY_X_GRAVITY_KEY}
                                                className="w-full"
                                            >
                                                <div className={`flex w-full flex-col lg:flex-row items-center`}>
                                                    {mobile_mode ? (
                                                        <>
                                                            <div className="flex flex-col w-full">
                                                                <p className="text-sm mb-0">{displayMessage('AFFECTED_PILLAR')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('AFFECTED_PILLARS')}
                                                                    placeholder={displayMessage('AFFECTED_PILLARS')}
                                                                    onChange={handleSelectAffectedPillar}
                                                                    value={selectedAffectedPillar?.title}
                                                                    options={affectedPillars.map(e => ({ label: e.title, value: e.id }))}
                                                                    allowClear
                                                                />

                                                                <p className="text-sm mb-0">{displayMessage('FREQUENCIES')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full flex"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('FREQUENCIES')}
                                                                    placeholder={displayMessage('FREQUENCIES')}
                                                                    onChange={handleSelectFrequency}
                                                                    value={selectedFrequency?.name}
                                                                    options={frequencies
                                                                        .filter(e => e.inactivation_date === null)
                                                                        .map(e => ({ label: e.description, value: e.id }))}
                                                                    allowClear
                                                                />
                                                                <p className="text-sm mb-0">{displayMessage('INSURANCE_COMPANIES')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full flex"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('INSURANCE_COMPANIES')}
                                                                    placeholder={displayMessage('INSURANCE_COMPANIES')}
                                                                    onChange={handleSelectInsurance}
                                                                    value={selectedInsurance?.name}
                                                                    options={insurancies
                                                                        .filter(e => e.inactivation_date === null)
                                                                        .map(e => ({ label: e.name, value: e.id }))}
                                                                    allowClear
                                                                />
                                                                <p className="text-sm mb-0">{displayMessage('CONTROL_MESURES')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('CONTROL_MESURES')}
                                                                    placeholder={displayMessage('CONTROL_MESURES')}
                                                                    onChange={handleSelectControlMesure}
                                                                    value={selectedControlMesure?.name}
                                                                    options={controlMesures
                                                                        .filter(e => e.inactivation_date === null)
                                                                        .map(e => ({ label: e.description, value: e.id }))}
                                                                    allowClear
                                                                />
                                                                <p className="text-sm mb-0">{displayMessage('DETECTIONS')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full flex"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('DETECTIONS')}
                                                                    placeholder={displayMessage('DETECTIONS')}
                                                                    onChange={handleSelectDetection}
                                                                    value={selectedDetection?.name}
                                                                    options={detections
                                                                        .filter(e => e.inactivation_date === null)
                                                                        .map(e => ({ label: e.description, value: e.id }))}
                                                                    allowClear
                                                                />

                                                                <p className="text-sm mb-0">{displayMessage('SEVERITIES')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full flex"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('SEVERITIES')}
                                                                    placeholder={displayMessage('SEVERITIES')}
                                                                    onChange={handleSelectSeverity}
                                                                    value={selectedSeverity?.name}
                                                                    options={severities
                                                                        .filter(e => e.inactivation_date === null)
                                                                        .map(e => ({ label: e.description, value: e.id }))}
                                                                    allowClear
                                                                />
                                                                <p className="text-sm mb-0">{displayMessage('COVERAGE_SCALES')}:</p>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-2 rounded-sm w-full"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('COVERAGE_SCALES')}
                                                                    placeholder={displayMessage('COVERAGE_SCALES')}
                                                                    onChange={handleSelectCoverageScale}
                                                                    value={selectedCoverageScale?.name}
                                                                    options={coverageScales
                                                                        .filter(e => e.inactivation_date === null)
                                                                        .map(e => ({ label: e.description, value: e.id }))}
                                                                    allowClear
                                                                />
                                                                <SL_Button className={'w-full'} onClick={handleOnCalculatePriorityLevel}>
                                                                    {displayMessage('CALCULATE_PRIORITY_LEVEL')}
                                                                </SL_Button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="flex w-full gap-5 flex-wrap">
                                                            <div className="flex w-full">
                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                    <p className="text-sm mb-0">{displayMessage('AFFECTED_PILLAR')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('AFFECTED_PILLARS')}
                                                                        placeholder={displayMessage('AFFECTED_PILLARS')}
                                                                        onChange={handleSelectAffectedPillar}
                                                                        value={selectedAffectedPillar?.title}
                                                                        options={affectedPillars.map(e => ({ label: e.title, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                    <p className="text-sm mb-0">{displayMessage('COVERAGE_SCALES')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('COVERAGE_SCALES')}
                                                                        placeholder={displayMessage('COVERAGE_SCALES')}
                                                                        onChange={handleSelectCoverageScale}
                                                                        value={selectedCoverageScale?.name}
                                                                        options={coverageScales
                                                                            .filter(e => e.inactivation_date === null)
                                                                            .map(e => ({ label: e.description, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                    <p className="text-sm mb-0">{displayMessage('INSURANCE_COMPANY')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('INSURANCE_COMPANY')}
                                                                        placeholder={displayMessage('INSURANCE_COMPANY')}
                                                                        onChange={handleSelectInsurance}
                                                                        value={selectedInsurance?.name}
                                                                        options={insurancies
                                                                            .filter(e =>e.inactivation_date ===null)
                                                                            .map(e => ({
                                                                                label: e.name,
                                                                                value: e.id,
                                                                            }))
                                                                        }
                                                                        allowClear
                                                                    />
                                                                    <SL_Button className={'w-full mt-7'} onClick={handleOnCalculatePriorityLevel}>
                                                                        {displayMessage('CALCULATE_PRIORITY_LEVEL')}
                                                                    </SL_Button>
                                                                </div>
                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                    <p className="text-sm mb-0">{displayMessage('FREQUENCIES')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('FREQUENCIES')}
                                                                        placeholder={displayMessage('FREQUENCIES')}
                                                                        onChange={handleSelectFrequency}
                                                                        value={selectedFrequency?.name}
                                                                        options={frequencies
                                                                            .filter(e => e.inactivation_date === null)
                                                                            .map(e => ({ label: e.description, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                    <p className="text-sm mb-0">{displayMessage('CONTROL_MESURES')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('CONTROL_MESURES')}
                                                                        placeholder={displayMessage('CONTROL_MESURES')}
                                                                        onChange={handleSelectControlMesure}
                                                                        value={selectedControlMesure?.name}
                                                                        options={controlMesures
                                                                            .filter(e => e.inactivation_date === null)
                                                                            .map(e => ({ label: e.description, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                    <p className="text-sm mb-0">{displayMessage('DETECTIONS')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('DETECTIONS')}
                                                                        placeholder={displayMessage('DETECTIONS')}
                                                                        onChange={handleSelectDetection}
                                                                        value={selectedDetection?.name}
                                                                        options={detections
                                                                            .filter(e => e.inactivation_date === null)
                                                                            .map(e => ({ label: e.description, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                    <p className="text-sm mb-0">{displayMessage('SEVERITIES')}:</p>
                                                                    <SL_Select
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 rounded-sm w-full"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('SEVERITIES')}
                                                                        placeholder={displayMessage('SEVERITIES')}
                                                                        onChange={handleSelectSeverity}
                                                                        value={selectedSeverity?.name}
                                                                        options={severities
                                                                            .filter(e => e.inactivation_date === null)
                                                                            .map(e => ({ label: e.description, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {selectedPriorityLevel != null && (
                                                    <>
                                                        <Card className="w-full bg-sl-gray-50 mt-2">
                                                            <div className="text-center w-full">
                                                                <div>Valor do nível de prioridade: {priorityLevelResult ?? ''}</div>
                                                                <div>
                                                                    {displayMessage('PRIORITY_LEVEL')}: {selectedPriorityLevel?.name}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('MODULE_DESCRIPTION')}: {selectedPriorityLevel?.description}
                                                                </div>
                                                                <div className="flex justify-center text-center align-middle">
                                                                    <div
                                                                        style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            borderRadius: 10,
                                                                            backgroundColor: selectedPriorityLevel?.color,
                                                                            justifySelf: 'center',
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </>
                                                )}
                                            </Panel>
                                            <Panel header={displayMessage('RISK_COMPLEMENT_DATA')} key={RISK_COMPLEMENT_DATA_KEY} className="w-full">
                                                <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-x-5`}>
                                                    <div className="w-1/3 flex-col">
                                                        {selectedClient != null && (
                                                            <>
                                                                <div className="flex justify-between">
                                                                    <p className="mb-2">{displayMessage('SELECT_ONE_WORK_SECTOR')}</p>
                                                                    <div
                                                                        className="cursor-pointer mr-2"
                                                                        onClick={() => setModalCreateWorkSectorIsOpen(true)}
                                                                    >
                                                                        <PlusCircleOutlined />

                                                                        {displayMessage('ADD_NEW_WORK_SECTOR')}
                                                                    </div>
                                                                    {modalCreateWorkSectorIsOpen && (
                                                                        <ModalCreateClientWorkSector
                                                                            isOpen={modalCreateWorkSectorIsOpen}
                                                                            onClose={setModalCreateWorkSectorIsOpen}
                                                                            client_id={clientId}
                                                                            reloadWorkSectors={loadWorkSectors}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className={`mb-2 w-full rounded-sm`}
                                                                    listItemHeight={100}
                                                                    title={displayMessage('WORK_SECTORS')}
                                                                    placeholder={selectedWorkSector?.name || displayMessage('WORK_SECTORS')}
                                                                    onChange={handleSelectedWorkSector}
                                                                    value={selectedWorkSector}
                                                                    options={workSectors?.map(e => ({ label: e.name, value: e.id }))}
                                                                    allowClear
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    {/* <div className="w-1/3 flex-col">
                                                    <p className="mb-2">{displayMessage('RESPONSIBLE_TECHNICAL')}</p>
                                                    <MultiSelect
                                                        charactersLimitToFetch={charactersLimitToFetch}
                                                        maxTagCount="responsive"
                                                        className="mb-2 w-full h-[28px] rounded-sm"
                                                        allowClear
                                                        fetchOptions={async (e) => mountClientUsers(e)}
                                                        placeholder={displayMessage('CLIENT_USERS')}
                                                        onChange={(clientUsers) => setSelectedClientUsersId(clientUsers.map(clientUser => clientUser.value))}
                                                    />
                                                </div> */}
                                                </div>

                                                <div className="w-full flex flex-row gap-x-5">
                                                    <div className="w-[48%] flex-col">
                                                        <p className="mb-2">{displayMessage('LOCATION')}</p>
                                                        <SL_Input
                                                            placeholder={displayMessage('LOCATION')}
                                                            onChange={e => setLocation(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <p className="mb-2">{displayMessage('OBSERVATION')}</p>
                                                        <SL_Input
                                                            rows={1}
                                                            textarea={true}
                                                            className="w-full"
                                                            placeholder={displayMessage('OBSERVATION')}
                                                            onChange={e => setObservations(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-full items-center justify-center mt-5">
                                                    <Card className="w-full bg-sl-gray-50">
                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full text-center gap-5`}>
                                                            <div className="flex-col">
                                                                <p>{displayMessage('REPORT_RISK_IMAGE')}</p>
                                                                <div className="flex w-full items-center justify-center text-center mt-3 gap-1">
                                                                    <Upload
                                                                        beforeUpload={() => false}
                                                                        listType="picture-card"
                                                                        fileList={reportRiskImage}
                                                                        onChange={handleFileOnChange}
                                                                        onPreview={handleOnFilePreview}
                                                                    >
                                                                        {reportRiskImage.length < 1 && '+'}
                                                                    </Upload>
                                                                </div>
                                                                <div className="text-xs">
                                                                    <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                                                                    <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <SL_Divider type="vertical" className="h-full bg-sl-gray-300" />
                                                            </div>
                                                            <div className="flex flex-col items-start justify-center w-full gap-y-2">
                                                                <p>{displayMessage('EVIDENCE_DESCRIPTION')}</p>
                                                                <SL_Input
                                                                    className="w-full"
                                                                    placeholder={displayMessage('EVIDENCE_DESCRIPTION')}
                                                                    onChange={e => setImageDescription(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                    <div className="w-[250px] flex items-center mt-2 ml-12">
                                        <SL_Button className={`flex w-full`} onClick={handleOnRiskIsFinished}>
                                            {displayMessage('SAVE_THIS_RISK_ON_REPORT')}
                                        </SL_Button>
                                    </div>
                                </div>
                            </>
                        )}

                        {risksWithRecommendationsAndNormatives.length > 0 && (
                            <Collapse accordion ghost>
                                <div className="mt-5 mb-4">{displayMessage('SELECTED_RISKS_TO_REPORT')}</div>
                                {risksWithRecommendationsAndNormatives.map(risk => {
                                    return (
                                        <Panel key={risk.id} header={risk?.title}>
                                            <List
                                                size="large"
                                                bordered
                                                dataSource={[risksWithRecommendationsAndNormatives.find(risk_rec => risk_rec.id == risk.id)]}
                                                renderItem={item => (
                                                    <>
                                                        <List.Item>
                                                            <div className="flex flex-col">
                                                                <div>
                                                                    {displayMessage('RISK')}: {item?.title}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('RECOMMENDATION')}: {item?.Recommendation?.title}
                                                                </div>
                                                                <div className="flex flex-row">
                                                                    <div className="mr-1">{displayMessage('NORMATIVES')}:</div>
                                                                    {item?.Normatives?.length > 0 ? (
                                                                        <div className="flex flex-col ml-2">
                                                                            {item?.Normatives.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        {displayMessage('TITLE')}: {item.title} |{' '}
                                                                                        {displayMessage('DESCRIPTION')} {item.description}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    ) : (
                                                                        displayMessage('EMPTY_01')
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('CONTROL_MESURE')}:{' '}
                                                                    {controlMesures.find(e => e.id == item.control_mesure_id)?.name ??
                                                                        displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('INSURANCE_COMPANY')}:{' '}
                                                                    {insurancies.find(e => e.id == item.insurance_companie_id)?.name ?? displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('FREQUENCY')}:{' '}
                                                                    {frequencies.find(e => e.id == item.frequency_id)?.name ??
                                                                        displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('DETECTION')}:{' '}
                                                                    {detections.find(e => e.id == item.detection_id)?.name ??
                                                                        displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('SEVERITY')}:{' '}
                                                                    {severities.find(e => e.id == item.severity_id)?.name ??
                                                                        displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('COVERAGE_SCALE')}:{' '}
                                                                    {coverageScales.find(e => e.id == item.coverage_scale_id)?.name ??
                                                                        displayMessage('EMPTY_01')}
                                                                </div>
                                                                {/* <div>
                                                        {displayMessage('PRIORITY_LEVEL')}: {priorityLevels.find(e => e.id == item.priority_level_id)?.name ?? displayMessage('EMPTY_01')}
                                                    </div> */}
                                                                <div>
                                                                    {displayMessage('AFFECTED_PILLAR')}:{' '}
                                                                    {affectedPillars.find(e => e.id == item.affected_pillar_id)?.title ??
                                                                        displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('PRIORITY_LEVEL')}:{' '}
                                                                    {item.priority_level ?? displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('LOCATION')}: {item.location ?? displayMessage('EMPTY_01')}
                                                                </div>

                                                                <div>
                                                                    {displayMessage('OBSERVATION')}: {item.observations ?? displayMessage('EMPTY_01')}
                                                                </div>
                                                                {/* <div>
                                                        {displayMessage('RESPONSIBLE_TECHNICAL')}: {item.client_user ?? displayMessage('EMPTY_01')}
                                                    </div> */}

                                                                <div>
                                                                    {displayMessage('WORK_SECTOR')}: {item.work_sector ?? displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('EVIDENCE_DESCRIPTION')}:{' '}
                                                                    {item.imageDescription ?? displayMessage('EMPTY_01')}
                                                                </div>
                                                                <div className="flex flex-col w-full justify-center items-center ">
                                                                    <img
                                                                        src={item?.image[0]?.thumbUrl}
                                                                        className="w-[150px] h-[150px] mt-5"
                                                                        style={{ borderRadius: '50%' }}
                                                                    />
                                                                    <div className="mt-2 text-center">
                                                                        {item?.imageDescription || 'Sem descriçao'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="ml-5 cursor-pointer"
                                                                onClick={() => handleOnRemoveSelectedRisk(item.temporary_id)}
                                                            >
                                                                <CloseCircleOutlined style={{ fontSize: '20px' }} />
                                                            </div>
                                                        </List.Item>
                                                    </>
                                                )}
                                            />
                                        </Panel>
                                    )
                                })}
                            </Collapse>
                        )}
                    </div>
                </Panel>
                <Panel header={displayMessage('STRENGHTS')} key={STRENGTHS_KEY}>
                    <div className="flex flex-col">
                        <p className="mb-2">{displayMessage('SELECT_STRENGHTS')}</p>
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-2 w-full h-[28px] rounded-sm"
                            mode="multiple"
                            listItemHeight={100}
                            title={displayMessage('STRENGHTS')}
                            placeholder={displayMessage('STRENGHTS')}
                            filterOption={filterOption}
                            onChange={handleStrengthsChange}
                            optionFilterProp="children"
                            options={strengths.map(e => ({ label: e.title, value: e.id }))}
                            allowClear
                            showSearch
                        />
                        {selectedStrengths.length > 0 && (
                            <List
                                size="large"
                                bordered
                                dataSource={strengths.filter(e => selectedStrengths.includes(e.id)) ?? []}
                                renderItem={item => (
                                    <List.Item>
                                        <div className="w-full flex flex-row mt-1">
                                            <div>{item?.title}</div>
                                            <div className="ml-5 cursor-pointer" onClick={() => handleOnRemoveStrenght(item.id)}>
                                                <CloseCircleOutlined style={{ fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        )}
                    </div>
                </Panel>
            </Collapse>
            <div className="w-full flex items-center justify-center mt-10">
                <SL_Button className={'w-[250px]'} disabled={!reportIsValid} onClick={sendReport}>
                    {displayMessage('CREATE_REPORT')}
                </SL_Button>
            </div>
        </>
    )
}
