import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../services/api'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { Card, Checkbox, Collapse, Form, List, Tabs, Tooltip, Upload } from 'antd'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import { SL_Button } from '../../../components/buttons/Button'
import { formatCnae, formatCnpj } from '../../../helpers/helper'
import { SL_Grid } from '../../../components/grid/SL_Grid'
import CustomToolbar from '../../../components/grid/CustomToolbar'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { report_risk_grid_columns } from '../grid_columns/report_risk_grid_columns'
import { ModalReportRiskDetails } from './ModalReportRiskDetails'
import { CloseCircleOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { SL_Select } from '../../../components/selects/Select'
import { EAlertTypes } from '../../../enums/EAlertTypes'
import { FollowUps } from './FollowUps/FollowUps'
import { ModalConfirmationDeleteRisk } from './ModalConfirmationDeleteRisk'
import { SL_Divider } from '../../../components/divider/Divider'
import { MultiSelect } from '../../../components/selects/MultiSelect'
import { ModalCreateClientWorkSector } from '../../listClients/components/ModalCreateClientSector'
import { RisksDetailPanel } from './RisksDetailPanel'
import { ModalResolveRiskConflicts } from './ModalResolveRiskConflicts'
import { useFetchReportData } from '../hooks/useFetchReportData'

const { Panel } = Collapse
let activeTab = 50
const TAB_REPORT_DETAIL_KEY = 50
const TAB_FOLLOW_UPS_LIST_KEY = 51

const GENERAL_DATA_KEY = 1
const PARTICIPANTS_KEY = 2
const RISKS_IDENTIFICATION_KEY = 3
const STRENGTHS_KEY = 4
const PROBABILITY_X_GRAVITY_KEY = 5
const NEW_RISK_KEY = 6
const RISK_COMPLEMENT_DATA_KEY = 7
const maxRequesterForFilter = 10

export function ReportDetails({}) {
    let eRiskTypes = null

    const { reportId } = useParams()

    const { mobile_mode, showAlert, setShowLoading } = useContext(GeneralContext)

    const [form] = Form.useForm()
    const reportUserFormRef = useRef()

    const [primaryCollapseActiveKey, setPrimaryCollapseActiveKey] = useState(null)
    const [secondaryCollapseActiveKey, setSecondaryCollapseActiveKey] = useState(null)

    const {
        riskTypes,
        risks,
        strengths,
        frequencies,
        controlMesures,
        affectedPillars,
        priorityLevels,
        coverageScales,
        severities,
        detections,
        reportConfigs,
        insurancies,
        setRisks,
    } = useFetchReportData()

    const [loading, setLoading] = useState(false)
    const [gridLoading, setGridLoading] = useState(false)

    const [report, setReport] = useState(null)

    const [columns, setColumns] = useState([])

    // const [reportConfigs, setReportConfigs] = useState([])
    const [selectedReportConfig, setSelectedReportConfig] = useState(null)

    const [reportRisks, setReportRisks] = useState([])

    // const [strengths, setStrengths] = useState([])
    const [followUps, setFollowUps] = useState([])

    const [selectedWorkSector, setSelectedWorkSector] = useState(null)

    // const [riskTypes, setRiskTypes] = useState([])
    const [selectedRiskType, setSelectedRiskType] = useState(null)

    const [riskActivities, setRiskActivities] = useState([])
    const [selectedRiskActivity, setSelectedRiskActivity] = useState(null)

    const [riskKinds, setRiskKinds] = useState([])
    const [selectedRiskKind, setSelectedRiskKind] = useState(null)

    // const [risks, setRisks] = useState([])
    const [selectedRisk, setSelectedRisk] = useState(null)

    // const [controlMesures, setControlMesures] = useState([])
    const [selectedControlMesure, setSelectedControlMesure] = useState(null)

    const [selectedInsurance, setSelectedInsurance] = useState(null)

    // const [frequencies, setFrequencies] = useState([])
    const [selectedFrequency, setSelectedFrequency] = useState(null)

    // const [detections, setDetections] = useState([])
    const [selectedDetection, setSelectedDetection] = useState(null)

    // const [severities, setSeverities] = useState([])
    const [selectedSeverity, setSelectedSeverity] = useState(null)

    // const [coverageScales, setCoverageScales] = useState([])
    const [selectedCoverageScale, setSelectedCoverageScale] = useState(null)

    // const [priorityLevels, setPriorityLevels] = useState([])
    const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null)
    const [priorityLevelResult, setPriorityLevelResult] = useState(null)

    // const [affectedPillars, setAffectedPillars] = useState([])
    const [selectedAffectedPillar, setSelectedAffectedPillar] = useState(null)

    const [reportRiskImage, setReportRiskImage] = useState([])
    const [imageDescription, setImageDescription] = useState(null)

    const [location, setLocation] = useState(null)

    const [observations, setObservations] = useState(null)

    const [risksWithRecommendationsAndNormatives, setRisksWithRecommendationsAndNormatives] = useState([])

    const [selectedAddress, setSelectedAddress] = useState(null)
    const [selectedReportUsers, setSelectedReportUsers] = useState([])
    const [selectedStrengths, setSelectedStrengths] = useState([])

    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [modalReportRiskDetailsIsOpen, setModalReportRiskDetailsIsOpen] = useState(false)
    const [modalConfirmationDeleteRiskIsOpen, setModalConfirmationDeleteRiskIsOpen] = useState(false)
    const [modalResolveConflictsOpen, setModalResolveConflictsOpen] = useState(false)
    const [selectedClientUsers, setSelectedClientUsers] = useState([])

    const [modalCreateWorkSectorIsOpen, setModalCreateWorkSectorIsOpen] = useState(false)

    const [workSectors, setWorkSectors] = useState(null)

    const rowOnFocusRef = useRef(null)

    useEffect(() => {
        handleOnTabChange(activeTab)
    }, [])

    useEffect(() => {
        if (risksWithRecommendationsAndNormatives.length > 0) {
            updateReport()
        }
    }, [risksWithRecommendationsAndNormatives])

    useEffect(() => {
        if (report) {
            loadWorkSectors()
            setSelectedAddress(report.address_id)
        }
    }, [report])

    useEffect(() => {
        rowOnFocusRef.current = rowOnFocus
    }, [rowOnFocus])

    useEffect(() => {
        const columns_to_set = report_risk_grid_columns(handleOnDownload)

        setColumns(columns_to_set)
    }, [report_risk_grid_columns])

    const isAddressDisabled = item => {
        if (item.already_used && item.id !== report.address_id) {
            return true
        }
        if (report?.finalization_date) {
            return true
        }
        return false
    }

    const loadReport = async (id = reportId) => {
        setGridLoading(true)
        try {
            if (!eRiskTypes) {
                const enumRes = await api.enums.find('ERiskTypes')
                eRiskTypes = enumRes.data
            }
            const { data } = await api.reports.find(id)

            setSelectedAddress(data.address_id)
            setSelectedReportUsers(data.ReportUsers)
            setSelectedStrengths(data.Strengths)
            setSelectedReportConfig(data.ReportConfig)
            setReport(data)
            const formattedRisks = await createReportRiskSequence(data.ReportRisks)
            setReportRisks(formattedRisks)

            if (rowOnFocusRef.current) {
                setRowOnFocus(formattedRisks.find(rr => rr.id === rowOnFocusRef.current.id))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const getSequenceDescription = async report_risk => {
        const risk_type = report_risk?.Risk?.RiskKind?.RiskActivity?.RiskType
        const risk_type_acronym = Object.values(eRiskTypes).find(e => risk_type?.code == e.code)?.acronym
        return risk_type_acronym
    }

    const createReportRiskSequence = async reportRisks => {
        let sequence,
            max_one_digit = 9,
            max_two_digits = 99
        const report_risks_formatted = []
        for (let i = 0; i < reportRisks.length; i++) {
            const report_risk = reportRisks[i]
            if (report_risk.is_from_app) {
                continue
            }
            const risk_type_acronym = await getSequenceDescription(report_risk)
            if (i < max_one_digit) {
                sequence = `00${i + 1}`
            } else if (i < max_two_digits) {
                sequence = `0${i + 1}`
            } else {
                sequence = `${i + 1}`
            }
            report_risks_formatted.push({ ...report_risk, sequence: `${risk_type_acronym}${sequence}` })
        }
        setReportRisks(report_risks_formatted)
        if (rowOnFocus) setRowOnFocus(reportRisks.find(rr => rr.id == rowOnFocus.id))

        return report_risks_formatted ?? []
    }

    const loadReportConfigs = async () => {
        try {
            const { data } = await api.reportConfigs.list()

            setReportConfigs(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadStrengths = async () => {
        try {
            const { data } = await api.strengths.list()

            setStrengths(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRisks = async () => {
        try {
            const { data } = await api.risks.list()

            setRisks(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadControlMesures = async () => {
        try {
            const { data } = await api.controlMesures.list()

            setControlMesures(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFrequencies = async () => {
        try {
            const { data } = await api.frequencies.list()

            setFrequencies(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadDetections = async () => {
        try {
            const { data } = await api.detections.list()

            setDetections(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadSeverities = async () => {
        try {
            const { data } = await api.severities.list()

            setSeverities(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadCoverageScales = async () => {
        try {
            const { data } = await api.coverageScales.list()

            setCoverageScales(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadPriorityLevels = async () => {
        try {
            const { data } = await api.priorityLevels.list()

            setPriorityLevels(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadAffectedPillars = async () => {
        try {
            const { data } = await api.affectedPillars.list()

            setAffectedPillars(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRiskType = async risk_type_id => {
        if (!risk_type_id) {
            setSelectedRiskType(null)
            return
        }

        setSelectedRiskType(risk_type_id)

        try {
            const { data } = await api.riskActivities.list({ risk_type_id })

            setRiskActivities(data)
            setSelectedRiskActivity(undefined)
            setRiskKinds([])
            setSelectedRiskKind(undefined)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRiskActivity = async risk_activity_id => {
        if (!risk_activity_id) {
            setSelectedRiskActivity(undefined)
            return
        }

        setSelectedRiskActivity(risk_activity_id)

        try {
            const { data } = await api.riskKinds.list({ risk_activity_id })

            setRiskKinds(data)
            setSelectedRiskKind(undefined)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRiskKind = async risk_kind_id => {
        if (!risk_kind_id) {
            setSelectedRiskKind(undefined)
            return
        }

        setSelectedRiskKind(risk_kind_id)

        try {
            const { data } = await api.risks.list({ risk_kind_id })

            setRisks(data)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnSelectedAddressChange = selected_address => {
        setSelectedAddress(selected_address)
    }

    const handleOnReportUserRemove = report_user_id => {
        const newReportUsers = selectedReportUsers.filter(e => e.id !== report_user_id)

        setSelectedReportUsers(newReportUsers)
    }

    const handleOnStrengthRemove = strength_id => {
        const newStrengths = selectedStrengths.filter(e => e.id != strength_id)

        setSelectedStrengths(newStrengths)
    }

    const handleNewReportUser = values => {
        console.log(values)
        setSelectedReportUsers(prev => [...prev, values])
        form.resetFields()
    }

    const handleStrengthsChange = strengths_ids => {
        const selectedStrenghts = strengths.filter(e => strengths_ids.includes(e.id))
        setSelectedStrengths(selectedStrenghts)
    }

    const handleSelectedRisk = async risk_id => {
        if (!risk_id) return

        try {
            const { data } = await api.risks.find(risk_id)
            setSelectedRisk(data)
            setPrimaryCollapseActiveKey(RISKS_IDENTIFICATION_KEY)
            setSecondaryCollapseActiveKey(NEW_RISK_KEY)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedWorkSector = work_sector => {
        if (!work_sector) {
            setSelectedWorkSector(null)
            return
        }
        setSelectedWorkSector(work_sector)
    }

    const handleSelectControlMesure = value => {
        setSelectedControlMesure(value)
    }

    const handleSelectInsurance = value => {
        setSelectedInsurance(value)
    }

    const handleSelectFrequency = value => {
        setSelectedFrequency(value)
    }

    const handleSelectDetection = value => {
        setSelectedDetection(value)
    }

    const handleSelectSeverity = value => {
        setSelectedSeverity(value)
    }

    const handleSelectCoverageScale = value => {
        setSelectedCoverageScale(value)
    }

    const validateProbabilityXGravityDataForCalculation = () => {
        if (!selectedControlMesure) {
            showAlert(displayMessage('DISPLAY_CONTROL_MESURE'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedDetection) {
            showAlert(displayMessage('DISPLAY_DETECTION'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedCoverageScale) {
            showAlert(displayMessage('DISPLAY_COVERAGE_SCALE'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedFrequency) {
            showAlert(displayMessage('DISPLAY_FREQUENCY', EAlertTypes.ERROR))
            return false
        }

        if (!selectedSeverity) {
            showAlert(displayMessage('DISPLAY_SEVERITY'), EAlertTypes.ERROR)
            return false
        }

        return true
    }

    const handleOnCalculatePriorityLevel = async () => {
        setShowLoading(true)
        try {
            const dataIsValidToCalculate = validateProbabilityXGravityDataForCalculation()

            if (dataIsValidToCalculate) {
                const params = {
                    control_mesure_id: selectedControlMesure,
                    detection_id: selectedDetection,
                    coverage_scale_id: selectedCoverageScale,
                    frequency_id: selectedFrequency,
                    severity_id: selectedSeverity,
                }

                const { data } = await api.priorityLevels.calculate(params)

                const { priority_level_entity, priority_level_result } = data

                setSelectedPriorityLevel(priority_level_entity)
                setPriorityLevelResult(priority_level_result)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const handleSelectAffectedPillar = value => {
        setSelectedAffectedPillar(value)
    }

    const handleSelectedReportConfig = report_config_id => {
        const selectedConfig = reportConfigs.find(e => e.id === report_config_id)
        setSelectedReportConfig(selectedConfig)
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setReportRiskImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const formatNames = names => {
        if (names.length === 2) {
            return names.join(' e ')
        } else if (names.length > 2) {
            return names.slice(0, -1).join(', ') + ' e ' + names[names.length - 1]
        } else {
            return names[0] || null
        }
    }

    const calculatePriorityLevelWhenFinishRisk = async () => {
        try {
            if (selectedControlMesure && selectedDetection && selectedCoverageScale && selectedFrequency && selectedSeverity) {
                const params = {
                    control_mesure_id: selectedControlMesure,
                    detection_id: selectedDetection,
                    coverage_scale_id: selectedCoverageScale,
                    frequency_id: selectedFrequency,
                    severity_id: selectedSeverity,
                }

                const { data } = await api.priorityLevels.calculate(params)

                const { priority_level_entity, priority_level_result } = data

                setSelectedPriorityLevel(priority_level_entity)
                setPriorityLevelResult(priority_level_result)

                return { priority_level_entity, priority_level_result }
            }
            return {}
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnRiskIsFinished = async () => {
        try {
            const { priority_level_entity, priority_level_result } = await calculatePriorityLevelWhenFinishRisk()
            const selectedRiskWithData = {
                temporary_id: crypto.randomUUID(),
                ...selectedRisk,
                control_mesure_id: selectedControlMesure,
                frequency_id: selectedFrequency,
                detection_id: selectedDetection,
                severity_id: selectedSeverity,
                coverage_scale_id: selectedCoverageScale,
                priority_level: (selectedPriorityLevel?.name || priority_level_entity?.name) ?? null,
                priority_level_id: (selectedPriorityLevel?.id || priority_level_entity?.id) ?? null,
                affected_pillar_id: selectedAffectedPillar,
                work_sector_id: selectedWorkSector,
                work_sector:
                    report?.Address?.Client?.WorkSectors?.find(e => e.id == selectedWorkSector)?.name ??
                    workSectors?.find(e => e.id == selectedWorkSector)?.name,
                clientUsersId: selectedClientUsers?.map(e => e.value),
                // work_sector: report?.Address?.Client?.WorkSectors?.find(e => e.id === selectedWorkSector)?.name,

                location,
                observations: observations,
                priority_level_level: priorityLevelResult ?? priority_level_result,
                image: reportRiskImage || null,
                image_description: imageDescription,
                client_user: formatNames(selectedClientUsers.map(e => e.label)),
                risk_id: selectedRisk.id,
                insurance_companie_id: selectedInsurance
            }

            delete selectedRiskWithData?.id
            setRisksWithRecommendationsAndNormatives(prev => [...prev, selectedRiskWithData])
            // await new Promise(resolve => {
            //     setRisksWithRecommendationsAndNormatives(prev => {
            //         const updatedRisks = [...prev, selectedRiskWithData];
            //         resolve(updatedRisks);
            //         return updatedRisks;
            //     });
            // });

            // await updateReport()

            setReportRiskImage([])
            setSelectedWorkSector(null)
            setSelectedRisk(undefined)
            setSelectedRiskKind(undefined)
            setSelectedRiskActivity(undefined)
            setSelectedRiskType(undefined)
            setSelectedControlMesure(null)
            setSelectedFrequency(null)
            setSelectedDetection(null)
            setSelectedSeverity(null)
            setSelectedCoverageScale(null)
            setSelectedPriorityLevel(null)
            setSelectedAffectedPillar(null)
            setLocation(null)
            setObservations(null)
            setPriorityLevelResult(null)
            setImageDescription(null)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnRemoveSelectedRisk = temporary_id => {
        if (!temporary_id) return

        const new_selected_risks = risksWithRecommendationsAndNormatives.filter(e => e.temporary_id !== temporary_id)

        setRisksWithRecommendationsAndNormatives(new_selected_risks)
    }

    const handlePrimaryCollapseChange = key => {
        setPrimaryCollapseActiveKey(key)
    }

    const handleSecondaryCollapseChange = key => {
        setSecondaryCollapseActiveKey(key)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const updateReport = async () => {
        setLoading(true)
        try {
            if (!selectedReportConfig) {
                showAlert(displayMessage('ERROR_NO_REPORT_CONFIG'), EAlertTypes.ERROR)
                return
            }

            if (!selectedAddress) {
                showAlert(displayMessage('ERROR_NO_ADDRESS'), EAlertTypes.ERROR)
                return false
            }

            const formData = new FormData()
            const reportObject = {
                selectedReportConfig,
                selectedAddress,
                selectedStrengths,
                risksWithRecommendationsAndNormatives,
                selectedReportUsers,
                selectedInsurance
            }
            formData.append('report_object', JSON.stringify(reportObject))

            for (let i = 0; i < risksWithRecommendationsAndNormatives.length; i++) {
                const risk = risksWithRecommendationsAndNormatives[i]

                if (risk.image.length > 0) {
                    const file = new File([risk.image[0].originFileObj], risk.image[0].name, { type: risk.image[0].type })
                    formData.append('attachments', file)
                }
            }
            await api.reportRisks.update(report.id, formData)

            setSelectedAddress(null)
            setRisksWithRecommendationsAndNormatives([])
            setSelectedReportUsers([])
            setSelectedRisk(null)
            setSelectedControlMesure(null)
            setSelectedFrequency(null)
            setSelectedDetection(null)
            setSelectedSeverity(null)
            setSelectedCoverageScale(null)
            setSelectedPriorityLevel(null)
            setSelectedAffectedPillar(null)
            setLocation(null)
            setImageDescription(null)
            setSelectedClientUsers([])

            await loadReport()
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    const loadFollowUps = async () => {
        setGridLoading(true)
        try {
            const { data } = await api.followUps.list({ report_id: reportId })

            createFollowUpsSequence(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const createFollowUpsSequence = followups => {
        let sequence,
            max_one_digit = 10,
            max_two_digits = 99
        const followups_formatted = []
        for (let i = 0; i < followups.length; i++) {
            const followup = followups[i]
            if (followup?.sequence < max_one_digit) {
                sequence = `00${followup?.sequence}`
            } else if (followup?.sequence < max_two_digits) {
                sequence = `0${followup?.sequence}`
            } else {
                sequence = `${followup?.sequence}`
            }
            followups_formatted.push({ ...followup, sequence_formatted: displayMessage('SEQUENCE_FOLLOWUP', [sequence]) })
        }
        setFollowUps(followups_formatted)
    }

    const handleOnTabChange = async key => {
        activeTab = key
        let listFunctionToCall = null
        const tabKeysWithYourListFunction = [
            { key_code: TAB_REPORT_DETAIL_KEY, list_function: loadReport },
            { key_code: TAB_FOLLOW_UPS_LIST_KEY, list_function: loadFollowUps },
        ]
        tabKeysWithYourListFunction.forEach(e => {
            if (key === e.key_code) listFunctionToCall = e.list_function
            return
        })

        if (listFunctionToCall) {
            await listFunctionToCall()
        }
    }

    // const mountClientUsers = async (e) => {
    //     try {
    //         const { data } = await api.clientUsers.listForSelectOptions({
    //             name: e,
    //             limit: maxRequesterForFilter,
    //             statusActivation: true,
    //             work_sector_id: selectedWorkSector
    //         })

    //         return data
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const loadWorkSectors = async params => {
        try {
            const { data } = await api.workSectors.list({ client_id: report?.Address?.Client?.id, ...params })

            setWorkSectors(data)
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const handleOnDownload = async attachment => {
        try {
            const { data } = await api.attachments.openAttachment(attachment.id, { responseType: 'blob' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${attachment?.name}`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const attachment_column = {
        field: 'attachments',
        headerName: displayMessage('ATTACHMENTS'),
        type: 'string',
        width: 450,
        filterable: false,
        renderCell: params => {
            const row = params.api.getRow(params.id)
            const attachment = row?.Attachment

            if (attachment?.file) {
                return (
                    <Tooltip
                        title={
                            <a
                                href={attachment.url}
                                download
                                style={{ display: 'block', marginBottom: '4px' }}
                                onClick={() => handleOnDownload(attachment)}
                            >
                                {attachment.name}
                            </a>
                        }
                        overlayStyle={{ maxWidth: 500 }}
                        overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                    >
                        <div className="flex flex-row">
                            <a
                                href={attachment.url}
                                download
                                style={{ display: 'block', marginBottom: '4px' }}
                                onClick={() => handleOnDownload(attachment)}
                            >
                                {attachment.name}
                            </a>
                        </div>
                    </Tooltip>
                )
            }
            return displayMessage('EMPTY_01')
        },
    }

    return (
        <>
            <Tabs
                activeKey={activeTab}
                onChange={handleOnTabChange}
                items={[
                    {
                        key: TAB_REPORT_DETAIL_KEY,
                        label: displayMessage('REPORT_DETAILS'),
                        children: (
                            <>
                                <div>
                                    {loading ? (
                                        <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                                            <SL_ClipLoader loading={true} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="w-full flex justify-center">
                                                <div className="mr-2 font-bold text-lg">{displayMessage('REPORT_EDITION')}</div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <Collapse
                                                    activeKey={primaryCollapseActiveKey}
                                                    onChange={handlePrimaryCollapseChange}
                                                    ghost
                                                    accordion
                                                    size="large"
                                                    className="w-full"
                                                >
                                                    <Panel header={displayMessage('GENERAL_DATA')} key={GENERAL_DATA_KEY}>
                                                        <div className="flex flex-col">
                                                            <p className="mb-2">{displayMessage('SELECT_ONE_REPORT_CONFIG')}</p>
                                                            <SL_Select
                                                                disabled={report?.finalization_date}
                                                                maxTagCount="responsive"
                                                                className="mb-2 w-80 h-[28px] rounded-sm"
                                                                listItemHeight={100}
                                                                title={displayMessage('REPORT_CONFIGS')}
                                                                placeholder={selectedReportConfig?.title || displayMessage('REPORT_CONFIGS')}
                                                                onChange={handleSelectedReportConfig}
                                                                value={selectedReportConfig?.title}
                                                                options={reportConfigs.map(e => ({ label: e.title, value: e.id }))}
                                                                allowClear
                                                            />
                                                        </div>
                                                        <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-5 mt-5`}>
                                                            <Card title={'Informações da empresa'} className="w-full bg-sl-gray-50 mt-5">
                                                                <div>
                                                                    {displayMessage('CLIENT_NAME')}: {report?.Address?.Client?.name}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('CLIENT_CNPJ')}:{' '}
                                                                    {report?.Address?.Client?.cnpj && formatCnpj(report?.Address?.Client?.cnpj)}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('CLIENT_NUMBER_CNAE')}:{' '}
                                                                    {report?.Address?.Client?.cnae_number &&
                                                                        formatCnae(report?.Address?.Client?.cnae_number)}
                                                                </div>
                                                                <div>
                                                                    {displayMessage('CLIENT_CNAE_DESCRIPTION')}: {report?.Address?.Client?.cnae}
                                                                </div>
                                                            </Card>
                                                            <Card title={'Endereços da empresa'} className="w-full bg-sl-gray-50 mt-5">
                                                                {report?.Address?.Client?.Addresses.map((item, index) => {
                                                                    const address_selected = selectedAddress == item.id
                                                                    return (
                                                                        <div key={item.id} className={`flex flex-row w-full`}>
                                                                            <div
                                                                                className={`flex flex-wrap flex-row ${
                                                                                    item.already_used == true ? 'underline' : ''
                                                                                }`}
                                                                            >
                                                                                <div className="mr-2">{item.state} -</div>
                                                                                <div className="mr-2">{item.city} -</div>
                                                                                <div className="mr-2">{item.neighbourhood} -</div>
                                                                                <div className="mr-2">{item.street} -</div>
                                                                                <div>{item.number}</div>
                                                                                <Checkbox
                                                                                    className="ml-5"
                                                                                    // disabled={item.already_used == true || report?.finalization_date}
                                                                                    disabled={isAddressDisabled(item)}
                                                                                    onChange={() => handleOnSelectedAddressChange(item.id)}
                                                                                    checked={address_selected}
                                                                                />
                                                                                {address_selected && (
                                                                                    <p className="ml-3 font-bold">ENDEREÇO SELECIONADO</p>
                                                                                )}
                                                                                {item.already_used == true &&
                                                                                    !address_selected &&
                                                                                    isAddressDisabled(item) && (
                                                                                        <div className="ml-5">
                                                                                            <Tooltip
                                                                                                placement="top"
                                                                                                title={displayMessage(
                                                                                                    'ADDRESS_ALREADY_USED_IN_ANOTHER_REPORT'
                                                                                                )}
                                                                                            >
                                                                                                <InfoCircleOutlined style={{ fontSize: '20px' }} />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Card>
                                                        </div>
                                                    </Panel>
                                                    <Panel header={displayMessage('PARTICIPANTS')} key={PARTICIPANTS_KEY}>
                                                        {report?.finalization_date == null && (
                                                            <>
                                                                <div className="mb-3">{displayMessage('CREATE_NEW_REPORT_USERS')}</div>

                                                                <Form
                                                                    onFinish={handleNewReportUser}
                                                                    className="w-full mb-5"
                                                                    layout="inline"
                                                                    requiredMark={false}
                                                                    form={form}
                                                                    ref={reportUserFormRef}
                                                                >
                                                                    <div
                                                                        className={`flex ${mobile_mode ? 'flex-col gap-0' : 'flex-row gap-5'} w-full`}
                                                                    >
                                                                        <div className={`flex flex-col gap-1 ${mobile_mode ? 'w-full' : 'w-1/2'}`}>
                                                                            <div className="">{displayMessage('FIRST_NAME')}</div>

                                                                            <Form.Item
                                                                                name="name"
                                                                                rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                                className="mb-4 w-full"
                                                                            >
                                                                                <SL_Input
                                                                                    placeholder={displayMessage('FIRST_NAME')}
                                                                                    className={'w-full'}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>

                                                                        <div className="flex flex-col gap-1 w-full">
                                                                            <div className="">{displayMessage('WORK_DESCRIPTION')}</div>

                                                                            <Form.Item
                                                                                name="work_description"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: displayMessage('DISPLAY_WORK_DESCRIPTION'),
                                                                                    },
                                                                                ]}
                                                                                className="mb-4 w-full"
                                                                            >
                                                                                <SL_Input
                                                                                    placeholder={displayMessage('WORK_DESCRIPTION')}
                                                                                    className={'w-full'}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>

                                                                        <div className="flex justify-center flex-col mt-2">
                                                                            <SL_Button
                                                                                onClick={() => reportUserFormRef.current.submit()}
                                                                                className={'w-full'}
                                                                            >
                                                                                {displayMessage('ADD')}
                                                                            </SL_Button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                        {selectedReportUsers.length > 0 ? (
                                                            <List
                                                                size="large"
                                                                bordered
                                                                dataSource={selectedReportUsers}
                                                                renderItem={item => (
                                                                    <List.Item>
                                                                        <div className="w-full flex flex-row mt-1">
                                                                            <div>
                                                                                {item?.name} | {item?.work_description}
                                                                            </div>
                                                                            <div
                                                                                className={`ml-5 ${!report?.finalization_date && 'cursor-pointer'}`}
                                                                                onClick={() =>
                                                                                    !report?.finalization_date && handleOnReportUserRemove(item.id)
                                                                                }
                                                                            >
                                                                                <CloseCircleOutlined style={{ fontSize: '20px' }} />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        ) : (
                                                            <p className="p-1">Nenhum participante selecionado</p>
                                                        )}
                                                    </Panel>
                                                    <Panel
                                                        header={displayMessage('RISKS_IDENTIFICATION')}
                                                        className="w-full"
                                                        key={RISKS_IDENTIFICATION_KEY}
                                                    >
                                                        {report?.finalization_date == null && (
                                                            <Collapse
                                                                activeKey={secondaryCollapseActiveKey}
                                                                onChange={handleSecondaryCollapseChange}
                                                                ghost
                                                                accordion
                                                                size="large"
                                                                className="w-full mb-5"
                                                            >
                                                                <Panel header={displayMessage('IDENTIFY_NEW_RISK')} key={NEW_RISK_KEY}>
                                                                    <div className="flex flex-col">
                                                                        <div className={`flex flex-wrap gap-5 border-solid border-sl-gray-200 p-5`}>
                                                                            <div className="flex flex-col">
                                                                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_TYPE')}</p>
                                                                                <SL_Select
                                                                                    showSearch
                                                                                    maxTagCount="responsive"
                                                                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                                                                    listItemHeight={100}
                                                                                    title={displayMessage('RISK_TYPES')}
                                                                                    placeholder={displayMessage('RISK_TYPES')}
                                                                                    onChange={handleSelectedRiskType}
                                                                                    value={selectedRiskType ?? undefined}
                                                                                    options={riskTypes.map(e => ({ label: e.name, value: e.id }))}
                                                                                    allowClear
                                                                                    filterOption={filterOption}
                                                                                />
                                                                            </div>

                                                                            <div className="flex flex-col">
                                                                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_ACTIVITY')}</p>
                                                                                <SL_Select
                                                                                    showSearch
                                                                                    maxTagCount="responsive"
                                                                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                                                                    listItemHeight={100}
                                                                                    disabled={selectedRiskType == null}
                                                                                    title={displayMessage('RISK_ACTIVITIES')}
                                                                                    placeholder={displayMessage('RISK_ACTIVITIES')}
                                                                                    onChange={handleSelectedRiskActivity}
                                                                                    value={selectedRiskActivity ?? undefined}
                                                                                    options={riskActivities.map(e => ({
                                                                                        label: e.name,
                                                                                        value: e.id,
                                                                                    }))}
                                                                                    allowClear
                                                                                    filterOption={filterOption}
                                                                                />
                                                                            </div>

                                                                            <div className="flex flex-col">
                                                                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_KIND')}</p>
                                                                                <SL_Select
                                                                                    showSearch
                                                                                    maxTagCount="responsive"
                                                                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                                                                    listItemHeight={100}
                                                                                    disabled={selectedRiskActivity == null}
                                                                                    title={displayMessage('RISK_KINDS')}
                                                                                    placeholder={displayMessage('RISK_KINDS')}
                                                                                    onChange={handleSelectedRiskKind}
                                                                                    value={selectedRiskKind ?? undefined}
                                                                                    options={riskKinds.map(e => ({ label: e.name, value: e.id }))}
                                                                                    allowClear
                                                                                    filterOption={filterOption}
                                                                                />
                                                                            </div>

                                                                            <div className="flex flex-col">
                                                                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK')}</p>
                                                                                <SL_Select
                                                                                    showSearch
                                                                                    maxTagCount="responsive"
                                                                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                                                                    listItemHeight={100}
                                                                                    disabled={selectedRiskActivity == null}
                                                                                    title={displayMessage('RISKS')}
                                                                                    placeholder={displayMessage('RISKS')}
                                                                                    onChange={handleSelectedRisk}
                                                                                    value={selectedRisk?.title ?? undefined}
                                                                                    options={risks.map(e => ({ label: e.title, value: e.id }))}
                                                                                    allowClear
                                                                                    filterOption={filterOption}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {selectedRisk != null && (
                                                                            <>
                                                                                <div className="flex flex-col items-center justify-center">
                                                                                    <div
                                                                                        className={`w-full flex ${
                                                                                            mobile_mode ? 'flex-col' : 'flex-row'
                                                                                        } gap-5 mt-5`}
                                                                                    >
                                                                                        <Card
                                                                                            className="w-full bg-sl-gray-50"
                                                                                            title={displayMessage('DESCRIPTION_FOR_THIS_RISK')}
                                                                                        >
                                                                                            {selectedRisk?.title}
                                                                                        </Card>
                                                                                    </div>
                                                                                    <div
                                                                                        className={`w-full flex ${
                                                                                            mobile_mode ? 'flex-col' : 'flex-row'
                                                                                        } gap-5 mt-5`}
                                                                                    >
                                                                                        <Card
                                                                                            className="w-full bg-sl-gray-50"
                                                                                            title={displayMessage('RECOMMENDATION_FOR_THIS_RISK')}
                                                                                        >
                                                                                            {selectedRisk?.Recommendation?.description}
                                                                                        </Card>
                                                                                        <Card
                                                                                            className="w-full bg-sl-gray-50 p-0"
                                                                                            title={displayMessage('NORMATIVES')}
                                                                                        >
                                                                                            <List
                                                                                                size="small"
                                                                                                locale={{
                                                                                                    emptyText:
                                                                                                        displayMessage(
                                                                                                            'NO_ONE_NORMATIVE_FOR_THIS_RISK'
                                                                                                        ),
                                                                                                }}
                                                                                                className="rounded p-0 m-0"
                                                                                                itemLayout="horizontal"
                                                                                                dataSource={selectedRisk?.Normatives}
                                                                                                renderItem={item => (
                                                                                                    <List.Item className="p-2">
                                                                                                        <p>{item.description}</p>
                                                                                                    </List.Item>
                                                                                                )}
                                                                                            />
                                                                                        </Card>
                                                                                    </div>

                                                                                    <div
                                                                                        className={`flex ${mobile_mode ? 'w-full' : 'w-11/12'} mt-5`}
                                                                                    >
                                                                                        <Collapse ghost accordion size="large" className="w-full">
                                                                                            <Panel
                                                                                                header={displayMessage('PROBABILITY_X_GRAVITY')}
                                                                                                key={PROBABILITY_X_GRAVITY_KEY}
                                                                                                className="w-full"
                                                                                            >
                                                                                                <div
                                                                                                    className={`flex w-full flex-col lg:flex-row items-center`}
                                                                                                >
                                                                                                    {mobile_mode ? (
                                                                                                        <>
                                                                                                            <div className="flex flex-col w-full">
                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage(
                                                                                                                        'AFFECTED_PILLAR'
                                                                                                                    )}
                                                                                                                    :
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage(
                                                                                                                        'AFFECTED_PILLARS'
                                                                                                                    )}
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'AFFECTED_PILLARS'
                                                                                                                    )}
                                                                                                                    onChange={
                                                                                                                        handleSelectAffectedPillar
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        selectedAffectedPillar?.title
                                                                                                                    }
                                                                                                                    options={affectedPillars.map(
                                                                                                                        e => ({
                                                                                                                            label: e.title,
                                                                                                                            value: e.id,
                                                                                                                        })
                                                                                                                    )}
                                                                                                                    allowClear
                                                                                                                />

                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage('INSURANCE_COMPANY')}:
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full flex"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage('INSURANCE_COMPANIES')}
                                                                                                                    placeholder={displayMessage('INSURANCE_COMPANIES')}
                                                                                                                    onChange={handleSelectInsurance}
                                                                                                                    value={selectedInsurance?.name}
                                                                                                                    options={insurancies
                                                                                                                        .filter(
                                                                                                                            e =>
                                                                                                                                e.inactivation_date ===
                                                                                                                                null
                                                                                                                        )
                                                                                                                        .map(e => ({
                                                                                                                            label: e.name,
                                                                                                                            value: e.id,
                                                                                                                        }))}
                                                                                                                    allowClear
                                                                                                                />

                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage('FREQUENCIES')}:
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full flex"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage(
                                                                                                                        'FREQUENCIES'
                                                                                                                    )}
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'FREQUENCIES'
                                                                                                                    )}
                                                                                                                    onChange={handleSelectFrequency}
                                                                                                                    value={selectedFrequency?.name}
                                                                                                                    options={frequencies
                                                                                                                        .filter(
                                                                                                                            e =>
                                                                                                                                e.inactivation_date ===
                                                                                                                                null
                                                                                                                        )
                                                                                                                        .map(e => ({
                                                                                                                            label: e.description,
                                                                                                                            value: e.id,
                                                                                                                        }))}
                                                                                                                    allowClear
                                                                                                                />
                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage(
                                                                                                                        'CONTROL_MESURES'
                                                                                                                    )}
                                                                                                                    :
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage(
                                                                                                                        'CONTROL_MESURES'
                                                                                                                    )}
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'CONTROL_MESURES'
                                                                                                                    )}
                                                                                                                    onChange={
                                                                                                                        handleSelectControlMesure
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        selectedControlMesure?.name
                                                                                                                    }
                                                                                                                    options={controlMesures
                                                                                                                        .filter(
                                                                                                                            e =>
                                                                                                                                e.inactivation_date ===
                                                                                                                                null
                                                                                                                        )
                                                                                                                        .map(e => ({
                                                                                                                            label: e.description,
                                                                                                                            value: e.id,
                                                                                                                        }))}
                                                                                                                    allowClear
                                                                                                                />
                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage('DETECTIONS')}:
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full flex"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage(
                                                                                                                        'DETECTIONS'
                                                                                                                    )}
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'DETECTIONS'
                                                                                                                    )}
                                                                                                                    onChange={handleSelectDetection}
                                                                                                                    value={selectedDetection?.name}
                                                                                                                    options={detections
                                                                                                                        .filter(
                                                                                                                            e =>
                                                                                                                                e.inactivation_date ===
                                                                                                                                null
                                                                                                                        )
                                                                                                                        .map(e => ({
                                                                                                                            label: e.description,
                                                                                                                            value: e.id,
                                                                                                                        }))}
                                                                                                                    allowClear
                                                                                                                />

                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage('SEVERITIES')}:
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full flex"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage(
                                                                                                                        'SEVERITIES'
                                                                                                                    )}
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'SEVERITIES'
                                                                                                                    )}
                                                                                                                    onChange={handleSelectSeverity}
                                                                                                                    value={selectedSeverity?.name}
                                                                                                                    options={severities
                                                                                                                        .filter(
                                                                                                                            e =>
                                                                                                                                e.inactivation_date ===
                                                                                                                                null
                                                                                                                        )
                                                                                                                        .map(e => ({
                                                                                                                            label: e.description,
                                                                                                                            value: e.id,
                                                                                                                        }))}
                                                                                                                    allowClear
                                                                                                                />
                                                                                                                <p className="text-sm mb-0">
                                                                                                                    {displayMessage(
                                                                                                                        'COVERAGE_SCALES'
                                                                                                                    )}
                                                                                                                    :
                                                                                                                </p>
                                                                                                                <SL_Select
                                                                                                                    maxTagCount="responsive"
                                                                                                                    className="mb-2 rounded-sm w-full"
                                                                                                                    listItemHeight={100}
                                                                                                                    title={displayMessage(
                                                                                                                        'COVERAGE_SCALES'
                                                                                                                    )}
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'COVERAGE_SCALES'
                                                                                                                    )}
                                                                                                                    onChange={
                                                                                                                        handleSelectCoverageScale
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        selectedCoverageScale?.name
                                                                                                                    }
                                                                                                                    options={coverageScales
                                                                                                                        .filter(
                                                                                                                            e =>
                                                                                                                                e.inactivation_date ===
                                                                                                                                null
                                                                                                                        )
                                                                                                                        .map(e => ({
                                                                                                                            label: e.description,
                                                                                                                            value: e.id,
                                                                                                                        }))}
                                                                                                                    allowClear
                                                                                                                />
                                                                                                                <SL_Button
                                                                                                                    className={'w-full'}
                                                                                                                    onClick={
                                                                                                                        handleOnCalculatePriorityLevel
                                                                                                                    }
                                                                                                                >
                                                                                                                    {displayMessage(
                                                                                                                        'CALCULATE_PRIORITY_LEVEL'
                                                                                                                    )}
                                                                                                                </SL_Button>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <div className="flex w-full gap-5 flex-wrap">
                                                                                                            <div className="flex w-full">
                                                                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage(
                                                                                                                            'AFFECTED_PILLAR'
                                                                                                                        )}
                                                                                                                        :
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage(
                                                                                                                            'AFFECTED_PILLARS'
                                                                                                                        )}
                                                                                                                        placeholder={displayMessage(
                                                                                                                            'AFFECTED_PILLARS'
                                                                                                                        )}
                                                                                                                        onChange={
                                                                                                                            handleSelectAffectedPillar
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            selectedAffectedPillar?.title
                                                                                                                        }
                                                                                                                        options={affectedPillars.map(
                                                                                                                            e => ({
                                                                                                                                label: e.title,
                                                                                                                                value: e.id,
                                                                                                                            })
                                                                                                                        )}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage(
                                                                                                                            'COVERAGE_SCALES'
                                                                                                                        )}
                                                                                                                        :
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage(
                                                                                                                            'COVERAGE_SCALES'
                                                                                                                        )}
                                                                                                                        placeholder={displayMessage(
                                                                                                                            'COVERAGE_SCALES'
                                                                                                                        )}
                                                                                                                        onChange={
                                                                                                                            handleSelectCoverageScale
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            selectedCoverageScale?.name
                                                                                                                        }
                                                                                                                        options={coverageScales
                                                                                                                            .filter(
                                                                                                                                e =>
                                                                                                                                    e.inactivation_date ===
                                                                                                                                    null
                                                                                                                            )
                                                                                                                            .map(e => ({
                                                                                                                                label: e.description,
                                                                                                                                value: e.id,
                                                                                                                            }))}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage('INSURANCE_COMPANY')}:
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage('INSURANCE_COMPANIES')}
                                                                                                                        placeholder={displayMessage('INSURANCE_COMPANIES')}
                                                                                                                        onChange={handleSelectInsurance}
                                                                                                                        value={
                                                                                                                            selectedInsurance?.name
                                                                                                                        }
                                                                                                                        options={insurancies
                                                                                                                            .filter(
                                                                                                                                e =>
                                                                                                                                    e.inactivation_date ===
                                                                                                                                    null
                                                                                                                            )
                                                                                                                            .map(e => ({
                                                                                                                                label: e.name,
                                                                                                                                value: e.id,
                                                                                                                            }))}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                    <SL_Button
                                                                                                                        className={'w-full mt-7'}
                                                                                                                        onClick={
                                                                                                                            handleOnCalculatePriorityLevel
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {displayMessage(
                                                                                                                            'CALCULATE_PRIORITY_LEVEL'
                                                                                                                        )}
                                                                                                                    </SL_Button>
                                                                                                                </div>
                                                                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage(
                                                                                                                            'FREQUENCIES'
                                                                                                                        )}
                                                                                                                        :
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage(
                                                                                                                            'FREQUENCIES'
                                                                                                                        )}
                                                                                                                        placeholder={displayMessage(
                                                                                                                            'FREQUENCIES'
                                                                                                                        )}
                                                                                                                        onChange={
                                                                                                                            handleSelectFrequency
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            selectedFrequency?.name
                                                                                                                        }
                                                                                                                        options={frequencies
                                                                                                                            .filter(
                                                                                                                                e =>
                                                                                                                                    e.inactivation_date ===
                                                                                                                                    null
                                                                                                                            )
                                                                                                                            .map(e => ({
                                                                                                                                label: e.description,
                                                                                                                                value: e.id,
                                                                                                                            }))}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage(
                                                                                                                            'CONTROL_MESURES'
                                                                                                                        )}
                                                                                                                        :
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage(
                                                                                                                            'CONTROL_MESURES'
                                                                                                                        )}
                                                                                                                        placeholder={displayMessage(
                                                                                                                            'CONTROL_MESURES'
                                                                                                                        )}
                                                                                                                        onChange={
                                                                                                                            handleSelectControlMesure
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            selectedControlMesure?.name
                                                                                                                        }
                                                                                                                        options={controlMesures
                                                                                                                            .filter(
                                                                                                                                e =>
                                                                                                                                    e.inactivation_date ===
                                                                                                                                    null
                                                                                                                            )
                                                                                                                            .map(e => ({
                                                                                                                                label: e.description,
                                                                                                                                value: e.id,
                                                                                                                            }))}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage('DETECTIONS')}
                                                                                                                        :
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage(
                                                                                                                            'DETECTIONS'
                                                                                                                        )}
                                                                                                                        placeholder={displayMessage(
                                                                                                                            'DETECTIONS'
                                                                                                                        )}
                                                                                                                        onChange={
                                                                                                                            handleSelectDetection
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            selectedDetection?.name
                                                                                                                        }
                                                                                                                        options={detections
                                                                                                                            .filter(
                                                                                                                                e =>
                                                                                                                                    e.inactivation_date ===
                                                                                                                                    null
                                                                                                                            )
                                                                                                                            .map(e => ({
                                                                                                                                label: e.description,
                                                                                                                                value: e.id,
                                                                                                                            }))}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                                                                                    <p className="text-sm mb-0">
                                                                                                                        {displayMessage('SEVERITIES')}
                                                                                                                        :
                                                                                                                    </p>
                                                                                                                    <SL_Select
                                                                                                                        maxTagCount="responsive"
                                                                                                                        className="mb-2 rounded-sm w-full"
                                                                                                                        listItemHeight={100}
                                                                                                                        title={displayMessage(
                                                                                                                            'SEVERITIES'
                                                                                                                        )}
                                                                                                                        placeholder={displayMessage(
                                                                                                                            'SEVERITIES'
                                                                                                                        )}
                                                                                                                        onChange={
                                                                                                                            handleSelectSeverity
                                                                                                                        }
                                                                                                                        value={selectedSeverity?.name}
                                                                                                                        options={severities
                                                                                                                            .filter(
                                                                                                                                e =>
                                                                                                                                    e.inactivation_date ===
                                                                                                                                    null
                                                                                                                            )
                                                                                                                            .map(e => ({
                                                                                                                                label: e.description,
                                                                                                                                value: e.id,
                                                                                                                            }))}
                                                                                                                        allowClear
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                                {selectedPriorityLevel != null && (
                                                                                                    <>
                                                                                                        <Card className="w-full bg-sl-gray-50 mt-2">
                                                                                                            <div className={`text-center w-full`}>
                                                                                                                <div>
                                                                                                                    Valor do nível de prioridade:{' '}
                                                                                                                    {priorityLevelResult ?? ''}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('PRIORITY_LEVEL')}
                                                                                                                    : {selectedPriorityLevel?.name}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage(
                                                                                                                        'MODULE_DESCRIPTION'
                                                                                                                    )}
                                                                                                                    :{' '}
                                                                                                                    {
                                                                                                                        selectedPriorityLevel?.description
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="flex justify-center text-center align-middle">
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            width: 20,
                                                                                                                            height: 20,
                                                                                                                            borderRadius: 10,
                                                                                                                            backgroundColor:
                                                                                                                                selectedPriorityLevel?.color,
                                                                                                                            justifySelf: 'center',
                                                                                                                        }}
                                                                                                                    ></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Card>
                                                                                                    </>
                                                                                                )}
                                                                                            </Panel>
                                                                                            <Panel
                                                                                                header={displayMessage('RISK_COMPLEMENT_DATA')}
                                                                                                key={RISK_COMPLEMENT_DATA_KEY}
                                                                                                className="w-full"
                                                                                            >
                                                                                                <div
                                                                                                    className={`w-full flex ${
                                                                                                        mobile_mode ? 'flex-col' : 'flex-row'
                                                                                                    } gap-x-5`}
                                                                                                >
                                                                                                    <div className="w-full flex-col">
                                                                                                        <div className="flex justify-between">
                                                                                                            <p className="mb-2">
                                                                                                                {displayMessage(
                                                                                                                    'SELECT_ONE_WORK_SECTOR'
                                                                                                                )}
                                                                                                            </p>
                                                                                                            <div
                                                                                                                className="cursor-pointer mr-2 "
                                                                                                                onClick={() =>
                                                                                                                    setModalCreateWorkSectorIsOpen(
                                                                                                                        true
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <PlusCircleOutlined />

                                                                                                                {displayMessage(
                                                                                                                    'ADD_NEW_WORK_SECTOR'
                                                                                                                )}
                                                                                                            </div>
                                                                                                            {modalCreateWorkSectorIsOpen && (
                                                                                                                <ModalCreateClientWorkSector
                                                                                                                    isOpen={
                                                                                                                        modalCreateWorkSectorIsOpen
                                                                                                                    }
                                                                                                                    onClose={
                                                                                                                        setModalCreateWorkSectorIsOpen
                                                                                                                    }
                                                                                                                    client_id={
                                                                                                                        report?.Address?.Client?.id
                                                                                                                    }
                                                                                                                    reloadWorkSectors={
                                                                                                                        loadWorkSectors
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <SL_Select
                                                                                                            maxTagCount="responsive"
                                                                                                            className={`mb-2 w-full rounded-sm`}
                                                                                                            listItemHeight={100}
                                                                                                            title={displayMessage('WORK_SECTORS')}
                                                                                                            placeholder={
                                                                                                                selectedWorkSector?.name ||
                                                                                                                displayMessage('WORK_SECTORS')
                                                                                                            }
                                                                                                            onChange={handleSelectedWorkSector}
                                                                                                            value={selectedWorkSector}
                                                                                                            options={
                                                                                                                workSectors?.map(e => ({
                                                                                                                    label: e.name,
                                                                                                                    value: e.id,
                                                                                                                })) ?? []
                                                                                                            }
                                                                                                            allowClear
                                                                                                        />
                                                                                                    </div>
                                                                                                    {/* <div className="flex flex-col w-full">
                                                                                                <p className="mb-2">{displayMessage('RESPONSIBLE_TECHNICAL')}</p>
                                                                                                <MultiSelect
                                                                                                    maxTagCount="responsive"
                                                                                                    className="mb-2 w-full h-[28px] rounded-sm"
                                                                                                    allowClear
                                                                                                    fetchOptions={async (e) => mountClientUsers(e)}
                                                                                                    placeholder={displayMessage('CLIENT_USERS')}
                                                                                                    onChange={(clientUsers) => setSelectedClientUsers(clientUsers.map(clientUser => clientUser))}
                                                                                                />
                                                                                            </div> */}

                                                                                                    <div className="w-full flex-col">
                                                                                                        <p className="mb-2">
                                                                                                            {displayMessage('LOCATION')}
                                                                                                        </p>
                                                                                                        <SL_Input
                                                                                                            placeholder={displayMessage('LOCATION')}
                                                                                                            onChange={e =>
                                                                                                                setLocation(e.target.value)
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="w-full flex flex-row gap-x-5">
                                                                                                    <div className="w-full">
                                                                                                        <p className="mb-2">
                                                                                                            {displayMessage('OBSERVATION')}
                                                                                                        </p>
                                                                                                        <SL_Input
                                                                                                            rows={1}
                                                                                                            textarea={true}
                                                                                                            className="w-full"
                                                                                                            placeholder={displayMessage(
                                                                                                                'OBSERVATION'
                                                                                                            )}
                                                                                                            onChange={e =>
                                                                                                                setObservations(e.target.value)
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flex w-full items-center justify-center mt-5">
                                                                                                    <Card className="w-full bg-sl-gray-50">
                                                                                                        <div
                                                                                                            className={`flex ${
                                                                                                                mobile_mode ? 'flex-col' : 'flex-row'
                                                                                                            } w-full text-center gap-5`}
                                                                                                        >
                                                                                                            <div className="flex-col">
                                                                                                                <p>
                                                                                                                    {displayMessage(
                                                                                                                        'REPORT_RISK_IMAGE'
                                                                                                                    )}
                                                                                                                </p>
                                                                                                                <div className="flex w-full items-center justify-center text-center mt-3 gap-1">
                                                                                                                    <Upload
                                                                                                                        beforeUpload={() => false}
                                                                                                                        listType="picture-card"
                                                                                                                        fileList={reportRiskImage}
                                                                                                                        onChange={handleFileOnChange}
                                                                                                                        onPreview={
                                                                                                                            handleOnFilePreview
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {reportRiskImage.length < 1 &&
                                                                                                                            '+'}
                                                                                                                    </Upload>
                                                                                                                </div>
                                                                                                                <div className="text-xs">
                                                                                                                    <p>
                                                                                                                        {displayMessage(
                                                                                                                            'RECOMMENDATION_DIMENSIONS'
                                                                                                                        )}
                                                                                                                    </p>
                                                                                                                    <p>
                                                                                                                        {displayMessage(
                                                                                                                            'RECOMMENDATION_SIZE'
                                                                                                                        )}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <SL_Divider
                                                                                                                    type="vertical"
                                                                                                                    className="h-full bg-sl-gray-300"
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="flex flex-col items-start justify-center w-full gap-y-2">
                                                                                                                <p>
                                                                                                                    {displayMessage(
                                                                                                                        'EVIDENCE_DESCRIPTION'
                                                                                                                    )}
                                                                                                                </p>
                                                                                                                <SL_Input
                                                                                                                    className="w-full"
                                                                                                                    placeholder={displayMessage(
                                                                                                                        'EVIDENCE_DESCRIPTION'
                                                                                                                    )}
                                                                                                                    onChange={e =>
                                                                                                                        setImageDescription(
                                                                                                                            e.target.value
                                                                                                                        )
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Card>
                                                                                                </div>
                                                                                            </Panel>
                                                                                        </Collapse>
                                                                                    </div>
                                                                                    <div className="w-full flex items-center justify-center mt-2">
                                                                                        <SL_Button
                                                                                            className={`${mobile_mode ? 'flex w-full' : 'w-[200px]'}`}
                                                                                            onClick={handleOnRiskIsFinished}
                                                                                        >
                                                                                            {displayMessage('SAVE_THIS_RISK_ON_REPORT')}
                                                                                        </SL_Button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {risksWithRecommendationsAndNormatives.length > 0 && (
                                                                            <Collapse accordion ghost>
                                                                                <div className="mt-5 mb-4">
                                                                                    {displayMessage('SELECTED_RISKS_TO_REPORT')}
                                                                                </div>
                                                                                {risksWithRecommendationsAndNormatives.map(risk => {
                                                                                    return (
                                                                                        <Panel key={risk.id} header={risk?.title}>
                                                                                            <List
                                                                                                size="large"
                                                                                                bordered
                                                                                                dataSource={[risk]}
                                                                                                renderItem={item => (
                                                                                                    <>
                                                                                                        <List.Item>
                                                                                                            <div className="flex flex-col">
                                                                                                                <div>
                                                                                                                    {displayMessage('RISK')}:{' '}
                                                                                                                    {item?.title ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('RECOMMENDATION')}
                                                                                                                    :{' '}
                                                                                                                    {item?.Recommendation?.title ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div className="flex flex-row">
                                                                                                                    <div className="mr-1">
                                                                                                                        {displayMessage('NORMATIVES')}
                                                                                                                        :
                                                                                                                    </div>
                                                                                                                    {item?.Normatives?.length > 0 ? (
                                                                                                                        <div className="flex flex-col ml-2">
                                                                                                                            {item?.Normatives.map(
                                                                                                                                (item, index) => {
                                                                                                                                    return (
                                                                                                                                        <div
                                                                                                                                            key={
                                                                                                                                                index
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {displayMessage(
                                                                                                                                                'TITLE'
                                                                                                                                            )}
                                                                                                                                            :{' '}
                                                                                                                                            {
                                                                                                                                                item.title
                                                                                                                                            }{' '}
                                                                                                                                            |{' '}
                                                                                                                                            {displayMessage(
                                                                                                                                                'DESCRIPTION'
                                                                                                                                            )}{' '}
                                                                                                                                            {
                                                                                                                                                item.description
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        displayMessage('EMPTY_01')
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('CONTROL_MESURE')}
                                                                                                                    :{' '}
                                                                                                                    {controlMesures.find(
                                                                                                                        e =>
                                                                                                                            e.id ==
                                                                                                                            item.control_mesure_id
                                                                                                                    )?.name ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('FREQUENCY')}:{' '}
                                                                                                                    {frequencies.find(
                                                                                                                        e => e.id == item.frequency_id
                                                                                                                    )?.name ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('DETECTION')}:{' '}
                                                                                                                    {detections.find(
                                                                                                                        e => e.id == item.detection_id
                                                                                                                    )?.name ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('SEVERITY')}:{' '}
                                                                                                                    {severities.find(
                                                                                                                        e => e.id == item.severity_id
                                                                                                                    )?.name ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('COVERAGE_SCALE')}
                                                                                                                    :{' '}
                                                                                                                    {coverageScales.find(
                                                                                                                        e =>
                                                                                                                            e.id ==
                                                                                                                            item.coverage_scale_id
                                                                                                                    )?.name ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                {/* <div>
                                                                                                                {displayMessage('PRIORITY_LEVEL')}: {priorityLevels.find(e => e.id == item.priority_level_id)?.name ?? displayMessage('EMPTY_01')}
                                                                                                            </div> */}
                                                                                                                <div>
                                                                                                                    {displayMessage(
                                                                                                                        'AFFECTED_PILLAR'
                                                                                                                    )}
                                                                                                                    :{' '}
                                                                                                                    {affectedPillars.find(
                                                                                                                        e =>
                                                                                                                            e.id ==
                                                                                                                            item.affected_pillar_id
                                                                                                                    )?.title ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('PRIORITY_LEVEL')}
                                                                                                                    :{' '}
                                                                                                                    {item.priority_level ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage('LOCATION')}:{' '}
                                                                                                                    {item.location ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>

                                                                                                                <div>
                                                                                                                    {displayMessage('OBSERVATION')}:{' '}
                                                                                                                    {item.observations ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                {/* <div>
                                                                                                                {displayMessage('RESPONSIBLE_TECHNICAL')}: {item.client_user ?? displayMessage('EMPTY_01')},
                                                                                                            </div> */}

                                                                                                                <div>
                                                                                                                    {displayMessage('WORK_SECTOR')}:{' '}
                                                                                                                    {item.work_sector ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {displayMessage(
                                                                                                                        'EVIDENCE_DESCRIPTION'
                                                                                                                    )}
                                                                                                                    :{' '}
                                                                                                                    {item.image_description ??
                                                                                                                        displayMessage('EMPTY_01')}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="ml-5 cursor-pointer"
                                                                                                                onClick={() =>
                                                                                                                    handleOnRemoveSelectedRisk(
                                                                                                                        item.temporary_id
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <CloseCircleOutlined
                                                                                                                    style={{ fontSize: '20px' }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </List.Item>
                                                                                                    </>
                                                                                                )}
                                                                                            />
                                                                                        </Panel>
                                                                                    )
                                                                                })}
                                                                            </Collapse>
                                                                        )}
                                                                    </div>
                                                                </Panel>
                                                            </Collapse>
                                                        )}

                                                        <div className="flex w-full">
                                                            {modalReportRiskDetailsIsOpen && (
                                                                <ModalReportRiskDetails
                                                                    isOpen={modalReportRiskDetailsIsOpen}
                                                                    onClose={setModalReportRiskDetailsIsOpen}
                                                                    reportRisk={rowOnFocus}
                                                                    reloadReportRisks={loadReport}
                                                                    isReportFinished={report?.finalization_date}
                                                                    addressId={selectedAddress}
                                                                    clientId={report?.Address?.Client?.id}
                                                                    setRowOnFocus={setRowOnFocus}
                                                                />
                                                            )}

                                                            {modalConfirmationDeleteRiskIsOpen && (
                                                                <ModalConfirmationDeleteRisk
                                                                    isOpen={modalConfirmationDeleteRiskIsOpen}
                                                                    onClose={setModalConfirmationDeleteRiskIsOpen}
                                                                    reportRiskId={rowOnFocus.id}
                                                                    reloadRisks={loadReport}
                                                                />
                                                            )}

                                                            {modalResolveConflictsOpen && (
                                                                <ModalResolveRiskConflicts
                                                                    isOpen={modalResolveConflictsOpen}
                                                                    onClose={setModalResolveConflictsOpen}
                                                                    conflict_id={rowOnFocus.conflict_id}
                                                                    reloadRisks={() => {
                                                                        loadReport()
                                                                    }}
                                                                />
                                                            )}

                                                            <SL_Grid
                                                                rows={reportRisks}
                                                                sortingMode={'server'}
                                                                components={{
                                                                    Toolbar: () => (
                                                                        <>
                                                                            <div className="flex justify-between flex-row ss:flex-row">
                                                                                <CustomToolbar />
                                                                                <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                                    <SL_Button
                                                                                        className="my-1 mr-1"
                                                                                        danger
                                                                                        disabled={!rowOnFocus || !rowOnFocus?.conflict_id}
                                                                                        onClick={() => setModalResolveConflictsOpen(true)}
                                                                                    >
                                                                                        {displayMessage('RESOLVE_CONFLICTS')}
                                                                                    </SL_Button>
                                                                                    <SL_Button
                                                                                        className="my-1 mr-1"
                                                                                        disabled={!rowOnFocus}
                                                                                        onClick={() => setModalReportRiskDetailsIsOpen(true)}
                                                                                    >
                                                                                        {displayMessage('RISK_DETAILS')}
                                                                                    </SL_Button>
                                                                                    <SL_Button
                                                                                        className="my-1 mr-1"
                                                                                        danger
                                                                                        disabled={
                                                                                            !rowOnFocus ||
                                                                                            report?.finalization_date ||
                                                                                            rowOnFocus?.conflict_id
                                                                                        }
                                                                                        onClick={() => setModalConfirmationDeleteRiskIsOpen(true)}
                                                                                    >
                                                                                        {displayMessage('DELETE_REPORT_RISK')}
                                                                                    </SL_Button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ),
                                                                }}
                                                                disableFilter={false}
                                                                onRowClick={params => {
                                                                    setRowOnFocus(params.row)
                                                                }}
                                                                columns={columns}
                                                                className="flex-1 min-h-[61vh] max-h-[100vh]"
                                                                loading={gridLoading}
                                                            />
                                                        </div>
                                                    </Panel>
                                                    <Panel header={displayMessage('STRENGHTS')} key={STRENGTHS_KEY}>
                                                        <div className="flex flex-col">
                                                            {
                                                                <>
                                                                    <p className="mb-2">{displayMessage('SELECT_STRENGHTS')}</p>
                                                                    <SL_Select
                                                                        disabled={report?.finalization_date}
                                                                        maxTagCount="responsive"
                                                                        className="mb-2 w-full h-[28px] rounded-sm"
                                                                        mode="multiple"
                                                                        listItemHeight={100}
                                                                        title={displayMessage('STRENGHTS')}
                                                                        placeholder={displayMessage('STRENGHTS')}
                                                                        onChange={handleStrengthsChange}
                                                                        value={selectedStrengths.map(e => e.id) ?? []}
                                                                        options={strengths.map(e => ({ label: e.title, value: e.id }))}
                                                                        allowClear
                                                                    />
                                                                </>
                                                            }
                                                            {selectedStrengths.length > 0 ? (
                                                                <List
                                                                    size="large"
                                                                    bordered
                                                                    dataSource={strengths.filter(e =>
                                                                        selectedStrengths.map(e => e.id).includes(e.id)
                                                                    )}
                                                                    renderItem={item => {
                                                                        return (
                                                                            <>
                                                                                <List.Item>
                                                                                    <div className="w-full flex flex-row mt-5">
                                                                                        <div>{item?.title}</div>
                                                                                        <div
                                                                                            className={`ml-5 ${
                                                                                                !report?.finalization_date && 'cursor-pointer'
                                                                                            }`}
                                                                                            onClick={() =>
                                                                                                !report?.finalization_date &&
                                                                                                handleOnStrengthRemove(item.id)
                                                                                            }
                                                                                        >
                                                                                            <CloseCircleOutlined style={{ fontSize: '20px' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </List.Item>
                                                                            </>
                                                                        )
                                                                    }}
                                                                />
                                                            ) : (
                                                                <p className="p-1">Nenhum ponto positivo selecionado</p>
                                                            )}
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                                <div className="w-full flex items-center justify-center mt-10">
                                                    <SL_Button
                                                        className={'w-2/4'}
                                                        onClick={updateReport}
                                                        disabled={report?.finalization_date != null}
                                                    >
                                                        {displayMessage('EDIT_REPORT')}
                                                    </SL_Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        ),
                    },
                    {
                        key: TAB_FOLLOW_UPS_LIST_KEY,
                        label: displayMessage('FOLLOW_UPS'),
                        children: <FollowUps loadFollowUps={loadFollowUps} followUps={followUps} gridLoading={gridLoading} />,
                    },
                ]}
            />
        </>
    )
}
