import { Button, List, Modal, Switch, Tooltip, Upload } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { useContext, useEffect, useState } from 'react'
import { SL_Select } from '../../../components/selects/Select'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import api from '../../../services/api'
import { SL_Input } from '../../../components/input/Input'
import { MultiSelect } from '../../../components/selects/MultiSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
import { ModalCreateClientWorkSector } from '../../listClients/components/ModalCreateClientSector'
import { Question } from '@phosphor-icons/react'
import defaultFileIcon from '../../../assets/files-icon.png'
const maxRequesterForFilter = 10

export function ModalReportRiskDetails({ isOpen, onClose, reportRisk, reloadReportRisks, isReportFinished, addressId, clientId, setRowOnFocus }) {
    const { mobile_mode } = useContext(GeneralContext)

    const [loading, setLoading] = useState(false)

    const [isEditing, setIsEditing] = useState(false)

    const [affectedPillars, setAffectedPillars] = useState([])
    const [selectedAffectedPillar, setSelectedAffectedPillar] = useState(reportRisk.AffectedPillar)

    const [insurancies, setInsurancies] = useState([])
    const [selectedInsurance, setSelectedInsurance] = useState(reportRisk.InsuranceCompany)

    const [controlMesures, setControlMesures] = useState([])
    const [selectedControlMesure, setSelectedControlMesure] = useState(reportRisk.ControlMesure)

    const [severities, setSeverities] = useState([])
    const [selectedSeverity, setSelectedSeverity] = useState(reportRisk.Severity)

    const [frequencies, setFrequencies] = useState(reportRisk)
    const [selectedFrequency, setSelectedFrequency] = useState(reportRisk.Frequency)

    const [coverageScales, setCoverageScales] = useState([])
    const [selectedCoverageScale, setSelectedCoverageScale] = useState(reportRisk.CoverageScale)

    const [priorityLevels, setPriorityLevels] = useState([])
    const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(reportRisk.PriorityLevel)

    const [detections, setDetections] = useState([])
    const [selectedDetection, setSelectedDetection] = useState(reportRisk.Detection)

    const [reportRiskImage, setReportRiskImage] = useState([])
    const [address, setAddress] = useState(null)
    const [selectedWorkSector, setSelectedWorkSector] = useState(reportRisk.work_sector_id)
    const [location, setLocation] = useState(reportRisk.location)
    const [selectedClientUsersId, setSelectedClientUsersId] = useState(reportRisk?.ReportRiskClientUsers.map(rp => rp.ClientUser.id))
    const [observations, setObservations] = useState(reportRisk.observations)
    const [imageDescription, setImageDescription] = useState(reportRisk.image_description)

    const [modalCreateWorkSectorIsOpen, setModalCreateWorkSectorIsOpen] = useState(false)

    const [workSectors, setWorkSectors] = useState(null)

    useEffect(() => {
        loadAllData()
        const data = api.attachments.imageDownload(reportRisk?.image_id)
    }, [])

    const loadAllData = async () => {
        setLoading(true)

        await Promise.all([
            loadAffectedPillars(),
            loadControlMesures(),
            loadSeverities(),
            loadFrequencies(),
            loadCoverageScales(),
            loadPriorityLevels(),
            loadDetections(),
            loadAddress(),
            loadWorkSectors(),
            loadInsurancies(),
        ])

        setLoading(false)
    }

    const onCloseModalReportRiskDetails = () => {
        onClose(false)
        setRowOnFocus(null)
    }

    const loadAddress = async () => {
        try {
            const { data } = await api.addresses.find(addressId)

            setAddress(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadAffectedPillars = async () => {
        try {
            const { data } = await api.affectedPillars.list()

            setAffectedPillars(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadInsurancies = async () => {
        try {
            const { data } = await api.insurancies.list()

            setInsurancies(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadControlMesures = async () => {
        try {
            const { data } = await api.controlMesures.list()
            setControlMesures(data)
        } catch (error) {
            console.log(error)
        }
    }

    const mountClientUsers = async e => {
        try {
            const { data } = await api.clientUsers.listForSelectOptions({
                name: e,
                limit: maxRequesterForFilter,
                statusActivation: true,
                work_sector_id: selectedWorkSector,
            })

            return data
        } catch (error) {
            console.log(error)
        }
    }

    const loadSeverities = async () => {
        try {
            const { data } = await api.severities.list()

            setSeverities(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFrequencies = async () => {
        try {
            const { data } = await api.frequencies.list()

            setFrequencies(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadCoverageScales = async () => {
        try {
            const { data } = await api.coverageScales.list()

            setCoverageScales(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadPriorityLevels = async () => {
        try {
            const { data } = await api.priorityLevels.list()

            setPriorityLevels(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadDetections = async () => {
        try {
            const { data } = await api.detections.list()

            setDetections(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnEditingChange = () => {
        setIsEditing(prev => !prev)
    }

    const handleOnSelectedAffectedPillarChange = affected_pillar_id => {
        const affectedPillar = affectedPillars.find(e => e.id == affected_pillar_id) ?? null
        setSelectedAffectedPillar(affectedPillar)
    }

    const handleOnSelectedInsuranceChange = insurance_id => {
        const target_insurance = insurancies.find(e => e.id == insurance_id) ?? null
        setSelectedInsurance(target_insurance)
    }

    const handleOnSelectedControlMesureChange = control_mesure_id => {
        const controlMesure = controlMesures.find(e => e.id == control_mesure_id) ?? null
        setSelectedControlMesure(controlMesure)
    }

    const handleOnSelectedSeverityChange = severity_id => {
        const severity = severities.find(e => e.id == severity_id) ?? null
        setSelectedSeverity(severity)
    }

    const handleOnSelectedFrequencyChange = frequency_id => {
        const frequency = frequencies.find(e => e.id == frequency_id) ?? null
        setSelectedFrequency(frequency)
    }

    const handleOnSelectedCoverageScaleChange = coverage_scale_id => {
        const coverageScale = coverageScales.find(e => e.id == coverage_scale_id) ?? null
        setSelectedCoverageScale(coverageScale)
    }

    const handleOnSelectedPriorityLevelChange = priority_level_id => {
        const priorityLevel = priorityLevels.find(e => e.id == priority_level_id) ?? null
        setSelectedPriorityLevel(priorityLevel)
    }

    const handleOnSelectedDetectionChange = detection_id => {
        const detection = detections.find(e => e.id == detection_id) ?? null
        setSelectedDetection(detection)
    }

    const handleSelectedWorkSector = work_sector_id => {
        if (!work_sector_id) {
            setSelectedWorkSector(null)
            return
        }

        setSelectedWorkSector(work_sector_id)
    }

    const riskItems = [
        {
            key: '1',
            label: <div className="font-bold">{displayMessage('RISK')}</div>,
            children: <p>{reportRisk?.Risk?.description}</p>,
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('RISK_DESCRIPTION')}</p>,
            children: <p>{reportRisk?.Risk?.description}</p>,
        },
    ]

    const recommendationItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('RECOMMENDATION')}</p>,
            children: <p>{reportRisk?.Recommendation?.description}</p>,
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('RECOMMENDATION_DESCRIPTION')}</p>,
            children: <p>{reportRisk?.Recommendation?.description}</p>,
        },
    ]

    const normativeItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('NORMATIVES')}</p>,
        },
    ]
    const sequenceItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('SEQUENCE')}</p>,
        },
    ]

    const affectedPillarItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('AFFECTED_PILLAR')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm w-full"
                            listItemHeight={100}
                            title={displayMessage('AFFECTED_PILLARS')}
                            placeholder={displayMessage('AFFECTED_PILLARS')}
                            onChange={handleOnSelectedAffectedPillarChange}
                            value={selectedAffectedPillar?.title}
                            options={affectedPillars.filter(e => e.inactivation_date === null).map(e => ({ label: e.title, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedAffectedPillar?.title}</p>
                    )}
                </>
            ),
        },
    ]



    const insuranceItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('INSURANCE_COMPANY')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm w-full"
                            listItemHeight={100}
                            title={displayMessage('INSURANCE_COMPANIES')}
                            placeholder={displayMessage('INSURANCE_COMPANIES')}
                            onChange={handleOnSelectedInsuranceChange}
                            value={selectedInsurance?.name}
                            options={insurancies.filter(e => e.inactivation_date === null).map(e => ({ label: e.name, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedInsurance?.title}</p>
                    )}
                </>
            ),
        },
    ]

    const controlMesureItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('CONTROL_MESURE')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm flex-auto max-w-full"
                            listItemHeight={100}
                            title={displayMessage('CONTROL_MESURES')}
                            placeholder={displayMessage('CONTROL_MESURES')}
                            onChange={handleOnSelectedControlMesureChange}
                            value={selectedControlMesure?.description}
                            options={controlMesures.filter(e => e.inactivation_date === null).map(e => ({ label: e.description, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedControlMesure?.description}</p>
                    )}
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('CONTROL_MESURE_DESCRIPTION')}</p>,
            children: <p>{selectedControlMesure?.description}</p>,
        },
    ]

    const severityItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('SEVERITY')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm flex-auto max-w-full"
                            listItemHeight={100}
                            title={displayMessage('SEVERITIES')}
                            placeholder={displayMessage('SEVERITIES')}
                            onChange={handleOnSelectedSeverityChange}
                            value={selectedSeverity?.description}
                            options={severities.filter(e => e.inactivation_date === null).map(e => ({ label: e.description, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedSeverity?.name}</p>
                    )}
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('SEVERITY_DESCRIPTION')}</p>,
            children: <p>{selectedSeverity?.description}</p>,
        },
    ]

    const frequencyItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('FREQUENCY')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm flex-auto max-w-full"
                            listItemHeight={100}
                            title={displayMessage('FREQUENCIES')}
                            placeholder={displayMessage('FREQUENCIES')}
                            onChange={handleOnSelectedFrequencyChange}
                            value={selectedFrequency?.description}
                            options={frequencies.filter(e => e.inactivation_date === null).map(e => ({ label: e.description, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedFrequency?.name}</p>
                    )}
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('FREQUENCY_DESCRIPTION')}</p>,
            children: <p>{selectedFrequency?.description}</p>,
        },
    ]

    const coverageScaleItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('COVERAGE_SCALE')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm flex-auto max-w-full"
                            listItemHeight={100}
                            title={displayMessage('COVERAGE_SCALES')}
                            placeholder={displayMessage('COVERAGE_SCALES')}
                            onChange={handleOnSelectedCoverageScaleChange}
                            value={selectedCoverageScale?.description}
                            options={coverageScales.filter(e => e.inactivation_date === null).map(e => ({ label: e.description, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedCoverageScale?.description}</p>
                    )}
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('COVERAGE_SCALE_DESCRIPTION')}</p>,
            children: <p>{selectedCoverageScale?.description}</p>,
        },
    ]

    const priorityLevelItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('PRIORITY_LEVEL')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <div>
                            <SL_Select
                                maxTagCount="responsive"
                                className="rounded-sm w-full"
                                listItemHeight={100}
                                title={displayMessage('PRIORITY_LEVELS')}
                                placeholder={displayMessage('PRIORITY_LEVELS')}
                                onChange={handleOnSelectedPriorityLevelChange}
                                value={selectedPriorityLevel?.name}
                                options={priorityLevels.filter(e => e.inactivation_date === null).map(e => ({ label: e.description, value: e.id }))}
                                allowClear
                            />
                        </div>
                    ) : (
                        <p>{selectedPriorityLevel?.name}</p>
                    )}
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('CONTROL_MESURE_DESCRIPTION')}</p>,
            children: <p>{selectedPriorityLevel?.description}</p>,
        },
        {
            key: '3',
            label: <p className="font-bold">{displayMessage('COLOR')}</p>,
            children: (
                <div
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        backgroundColor: selectedPriorityLevel?.color,
                        justifySelf: 'center',
                    }}
                ></div>
            ),
        },
    ]

    const detectionItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('DETECTION')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <SL_Select
                            maxTagCount="responsive"
                            className="rounded-sm flex-auto max-w-full"
                            listItemHeight={100}
                            title={displayMessage('DETECTIONS')}
                            placeholder={displayMessage('DETECTIONS')}
                            onChange={handleOnSelectedDetectionChange}
                            value={selectedDetection?.description}
                            options={detections.filter(e => e.inactivation_date === null).map(e => ({ label: e.description, value: e.id }))}
                            allowClear
                        />
                    ) : (
                        <p>{selectedDetection?.description}</p>
                    )}
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('DETECTION_DESCRIPTION')}</p>,
            children: <p>{selectedDetection?.description}</p>,
        },
    ]

    const loadWorkSectors = async params => {
        try {
            const { data } = await api.workSectors.list({ client_id: clientId, ...params })

            setWorkSectors(data)
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const workSectorItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('WORK_SECTOR')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <div className="w-full">
                            <SL_Select
                                maxTagCount="responsive"
                                className="rounded-sm flex-auto max-w-full w-full"
                                listItemHeight={100}
                                title={displayMessage('WORK_SECTORS')}
                                placeholder={displayMessage('WORK_SECTORS')}
                                onChange={handleSelectedWorkSector}
                                defaultValue={reportRisk?.WorkSector?.name}
                                options={workSectors.filter(e => e.inactivation_date === null).map(e => ({ label: e.name, value: e.id }))}
                                allowClear
                            />
                            <div className="cursor-pointer mr-2 " onClick={() => setModalCreateWorkSectorIsOpen(true)}>
                                <PlusCircleOutlined />
                                {displayMessage('ADD_NEW_WORK_SECTOR')}
                            </div>
                            {modalCreateWorkSectorIsOpen && (
                                <ModalCreateClientWorkSector
                                    isOpen={modalCreateWorkSectorIsOpen}
                                    onClose={setModalCreateWorkSectorIsOpen}
                                    client_id={clientId}
                                    reloadWorkSectors={loadWorkSectors}
                                />
                            )}
                        </div>
                    ) : (
                        <p>{reportRisk?.WorkSector?.name ?? displayMessage('EMPTY_01')}</p>
                    )}
                </>
            ),
        },
    ]

    const locationItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('LOCATION')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <div className="flex justify-center items-center text-center">
                            <SL_Input
                                placeholder={displayMessage('LOCATION')}
                                onChange={e => setLocation(e.target.value)}
                                defaultValue={reportRisk.location}
                            />
                        </div>
                    ) : (
                        <p>{reportRisk.location ?? displayMessage('EMPTY_01')}</p>
                    )}
                </>
            ),
        },
    ]
    // const clientUsersItems = [
    //     {
    //         key: '1',
    //         label: <p className="font-bold">{displayMessage('RESPONSIBLE_TECHNICAL')}</p>,
    //         children: <>
    //             {
    //                 isEditing
    //                 ?
    //                 <div className="flex justify-center items-center text-center">
    //                     <MultiSelect
    //                         maxTagCount="responsive"
    //                         className="mb-2 w-full h-[28px] rounded-sm"
    //                         allowClear
    //                         fetchOptions={async (e) => mountClientUsers(e)}
    //                         placeholder={displayMessage('RESPONSIBLE_TECHNICAL')}
    //                         defaultValue={reportRisk?.ReportRiskClientUsers.map(rp => {
    //                             return {
    //                                 label: `${rp.ClientUser.first_name} ${rp.ClientUser.last_name}`,
    //                                 value: rp.ClientUser.id
    //                             }
    //                         })}
    //                         onChange={(clientUsers) => setSelectedClientUsersId(clientUsers.map(clientUser => clientUser?.value))}
    //                         />
    //                 </div>
    //                     :
    //                     <p>{reportRisk?.ReportRiskClientUsers.length > 0 ? reportRisk?.ReportRiskClientUsers.map(rp => `${rp.ClientUser.first_name} ${rp.ClientUser.last_name}`).join(', ') : displayMessage('EMPTY_01')}</p>

    //                 }
    //         </>,
    //     },
    // ]

    const observationItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('OBSERVATIONS')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <div className="flex justify-center items-center text-center">
                            <SL_Input
                                placeholder={displayMessage('OBSERVATIONS')}
                                onChange={e => setObservations(e.target.value)}
                                defaultValue={reportRisk.observations}
                            />
                        </div>
                    ) : (
                        <p>{reportRisk.observations ?? displayMessage('EMPTY_01')}</p>
                    )}
                </>
            ),
        },
    ]

    const imageDescriptionItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('EVIDENCE_DESCRIPTION')}</p>,
            children: (
                <>
                    {isEditing ? (
                        <div className="flex justify-center items-center text-center">
                            <SL_Input
                                placeholder={displayMessage('EVIDENCE_DESCRIPTION')}
                                onChange={e => setImageDescription(e.target.value)}
                                defaultValue={reportRisk.image_description}
                            />
                        </div>
                    ) : (
                        <p>{reportRisk.image_description ?? displayMessage('EMPTY_01')}</p>
                    )}
                </>
            ),
        },
    ]

    const onSaveAlterations = async () => {
        setLoading(true)
        try {
            const updatedGeneralData = {
                selectedAffectedPillar,
                selectedControlMesure,
                selectedSeverity,
                selectedFrequency,
                selectedCoverageScale,
                selectedDetection,
                location,
                observations,
                clientUsersId: selectedClientUsersId,
                selectedWorkSector,
                imageDescription,
                selectedInsurance
            }

            await api.reportRisks.updateGeneralData(reportRisk?.id, updatedGeneralData)

            await onSaveImageAlteration()
            await reloadReportRisks(), onCloseModalReportRiskDetails()
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onSaveImageAlteration = async () => {
        if (reportRiskImage.length > 0) {
            try {
                const formData = new FormData()

                const file = new File([reportRiskImage[0].originFileObj], reportRiskImage[0].name, { type: reportRiskImage[0].type })

                formData.append('attachment', file)

                await api.reportRisks.updateImage(reportRisk.id, formData)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setReportRiskImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const renderList = (dataSource, emptyText) => {
        return (
            <>
                <List
                    size="small"
                    locale={{ emptyText }}
                    className="rounded p-0 m-0"
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={item => (
                        <List.Item className="p-2">
                            <p>{item.description}</p>
                        </List.Item>
                    )}
                />
            </>
        )
    }

    const handleImageDownload = () => {
        try {
            const imageUrl = api.attachments.imageDownload(reportRisk?.image_id)
            const link = document.createElement('a')
            link.href = imageUrl
            link.download = 'downloaded_image.jpg'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.log(error)
        }
    }

    const verifyRiskAttachmentType = reportRisk => {
        if ((reportRisk?.Attachment?.file_type).includes('image')) {
            return api.attachments.imageDownload(reportRisk?.image_id)
        } else {
            return defaultFileIcon
        }
    }

    return (
        <Modal
            title={displayMessage('REPORT_RISK_DETAILS')}
            centered
            style={{ padding: 20 }}
            open={isOpen}
            width={1000}
            onCancel={onCloseModalReportRiskDetails}
            footer={[
                <Button onClick={onCloseModalReportRiskDetails}>{displayMessage('GO_BACK')}</Button>,
                isEditing && (
                    <Button type="primary" onClick={() => onSaveAlterations()} loading={loading}>
                        {displayMessage('SAVE')}
                    </Button>
                ),
            ]}
            maskClosable={false}
        >
            <div className="flex w-full flex-col">
                {!isReportFinished && (
                    <div className="w-full flex flex-row mt-4 justify-end">
                        <div className="mr-3">{displayMessage('ENABLE_EDIT')}</div>
                        <Switch checked={isEditing} onChange={handleOnEditingChange} disabled={reportRisk?.conflict_id} />
                        {reportRisk?.conflict_id && (
                            <div className="flex ml-3">
                                <Tooltip title={displayMessage('EDITING_RISK_CONFLICT_PAINEL')}>
                                    <Question
                                        size={25}
                                        overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                                        className="font-bold cursor-pointer w-full"
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}
                {
                    <>
                        <div className="mt-3 w-full flex flex-col gap-2">
                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{riskItems[0].label}</p>
                                </div>
                                <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                                    <div className="flex flex-row">
                                        <p className="ml-2">{reportRisk?.Risk?.description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{normativeItems[0].label}</p>
                                </div>
                                <Tooltip
                                    autoAdjustOverflow={true}
                                    placement="bottom"
                                    title={renderList(reportRisk?.Risk?.Normatives, displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK'))}
                                    overlayStyle={{ maxWidth: 500 }}
                                    overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                                >
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm max-h-[100px] min-h-[50px] w-full overflow-hidden justify-start text-start">
                                        <div className="flex flex-row">
                                            {renderList(reportRisk?.Risk?.Normatives, displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK'))}
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{recommendationItems[0].label}</p>
                                </div>
                                <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                                    <div className="flex flex-row">
                                        <p className="ml-2">{reportRisk?.Risk?.Recommendation?.description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{sequenceItems[0].label}</p>
                                </div>
                                <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                                    <div className="flex flex-row">
                                        <p className="ml-2">{reportRisk.sequence}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{priorityLevelItems[0].label}</p>
                                </div>
                                <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                                    <div className="flex flex-row">
                                        <p className="ml-2">{reportRisk?.PriorityLevel?.name || displayMessage('EMPTY_01')}</p>
                                    </div>
                                    <div className="flex flex-row">
                                        <p className="ml-2">{reportRisk?.PriorityLevel?.description || displayMessage('EMPTY_01')}</p>
                                    </div>
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: 10,
                                            backgroundColor: selectedPriorityLevel?.color,
                                            justifySelf: 'center',
                                            marginLeft: '6px',
                                        }}
                                    ></div>
                                </div>
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{affectedPillarItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start ml-2 items-center">{affectedPillarItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {reportRisk?.AffectedPillar?.title ?? displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{insuranceItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start ml-2 items-center">{insuranceItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {reportRisk?.InsuranceCompany?.name ?? displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>
                            

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{frequencyItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start items-center ml-2 max-w-[75%]">{frequencyItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {reportRisk?.Frequency?.description || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{controlMesureItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start items-center ml-2 max-w-[75%]">{controlMesureItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                                        <div className="flex flex-row">
                                            <p className="">{reportRisk?.ControlMesure?.description ?? displayMessage('EMPTY_01')}</p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{detectionItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start items-center ml-2 max-w-[75%]">{detectionItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {reportRisk?.Detection?.description || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{severityItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start items-center ml-2 max-w-[75%]">{severityItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {reportRisk?.Severity?.description || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{coverageScaleItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start items-center ml-2 max-w-[75%]">{coverageScaleItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {reportRisk?.CoverageScale?.description || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{workSectorItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start items-center ml-2 max-w-[75%]">{workSectorItems[0].children}</div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {workSectorItems[0].children || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{locationItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start">
                                        <div className="w-full ml-2" style={{ alignSelf: 'center' }}>
                                            {locationItems[0].children}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {locationItems[0].children || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{observationItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start">
                                        <div className="w-full ml-2" style={{ alignSelf: 'center' }}>
                                            {observationItems[0].children}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {observationItems[0].children || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                                <div
                                    className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                                    style={{ width: mobile_mode ? '100%' : '25%', minWidth: mobile_mode ? '100%' : '25%' }}
                                >
                                    <p style={{ alignSelf: 'center' }}>{imageDescriptionItems[0].label}</p>
                                </div>
                                {isEditing ? (
                                    <div className="flex w-full justify-start">
                                        <div className="w-full ml-2" style={{ alignSelf: 'center' }}>
                                            {imageDescriptionItems[0].children}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start max-w-[75%]">
                                        {imageDescriptionItems[0].children || displayMessage('EMPTY_01')}
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-row w-full h-32  justify-center">
                                {isEditing ? (
                                    <>
                                        <div className="flex flex-row w-full text-center justify-center items-center">
                                            <div className="flex flex-col">
                                                <div className="">{displayMessage('REPORT_RISK_IMAGE')}</div>
                                                <div className="flex w-full items-center justify-center text-center mt-3 gap-1">
                                                    <Upload
                                                        beforeUpload={() => false}
                                                        listType="picture-card"
                                                        fileList={reportRiskImage}
                                                        onChange={handleFileOnChange}
                                                        onPreview={handleOnFilePreview}
                                                    >
                                                        {reportRiskImage.length < 1 && '+'}
                                                    </Upload>
                                                </div>
                                            </div>
                                            <div className="text-xs">
                                                <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                                                <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <div>{displayMessage('CURRENT_EVIDENCE')}</div>
                                        {reportRisk?.image_id ? (
                                            <img
                                                src={verifyRiskAttachmentType(reportRisk)}
                                                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                                                onClick={handleImageDownload}
                                            />
                                        ) : (
                                            <h3 className="mt-5">{displayMessage('NO_EVIDENCE_REGISTERED')}</h3>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                }
            </div>
        </Modal>
    )
}
