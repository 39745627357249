import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import { InternalLayout } from './components/layouts/InternalLayout'
import { ExternalLayout } from './components/layouts/ExternalLayout'
import { ResetPasswordForm } from '../screens/resetPassword/ResetPasswordForm'
import { Login } from '../screens/login/Login'
import { ChangePassword } from '../screens/changePassword/ChangePassword'
import { Breadcrumb } from '../components/breadcrumb/Breadcrumb'
import { GeneralRoute } from './components/GeneralRoute'
import Profile from '../screens/profile/Profile'
import DashBoard from '../screens/dashBoard/DashBoard'
import ListUsers from '../screens/listUsers/ListUsers'
import ListClients from '../screens/listClients/ListClients'
import { UserDetails } from '../screens/listUsers/components/UserDetails'
import { ClientDetails } from '../screens/listClients/components/ClientDetails'
import { ClientUser } from '../screens/clientUser/ClientUser'
import { WorkSectorDetails } from '../screens/listClients/components/WorkSectorDetails'
import { ClientAddressDetails } from '../screens/listClients/components/ClientAddressDetails'
import { ControlMesureDetails } from '../screens/probability_x_gravity/components/ControlMesureDetails'
import { FrequenciesDetails } from '../screens/probability_x_gravity/components/FrequenciesDetails'
import { DetectionDetails } from '../screens/probability_x_gravity/components/DetectionDetails'
import { SeveritiesDetails } from '../screens/probability_x_gravity/components/SeveritiesDetails'
import { CoverageScalesDetails } from '../screens/probability_x_gravity/components/CoverageScalesDetails'
import { PriorityLevelsDetails } from '../screens/probability_x_gravity/components/PriorityLevelsDetails'
import { Risks } from '../screens/risks/Risks'
import { RiskDetails } from '../screens/risks/components/RiskDetails'
import { NormativeDetails } from '../screens/risks/components/NormativeDetails'
import { RecommendationsDetails } from '../screens/risks/components/RecommendationDetails'
import { Reports } from '../screens/reports/Reports'
import { AffectedPillarsDetails } from '../screens/risks/components/AffectedPillarsDetails'
import { StrengthDetails } from '../screens/risks/components/StrengthDetails'
import { ReportDetails } from '../screens/reports/components/ReportDetails'
import { FollowUpDetails } from '../screens/reports/components/FollowUps/components/FollowUpDetails'
import { ProbabilityXGravity } from '../screens/probability_x_gravity/ProbabilityXGravity'
import { Settings } from '../screens/settings/Settings'
import { PermissionedRoute } from './components/PermissionedRoute'
import EUserTypes from '../enums/EUserTypes'
import { CreateReportConfig } from '../screens/settings/components/CreateReportConfig'
import { ReportConfigDetails } from '../screens/settings/components/ReportConfigDetails'
import ListClientUsers from '../screens/clientUser copy/ListClientUsers'
import { ClientUserDetails } from '../screens/clientUser copy/components/ClientUserDetail'
import { InsuranceCompanyDetails } from '../screens/risks/components/InsuranceCompanyDetails'

const router = createBrowserRouter([
    {
        path: '/',
        element: <GeneralRoute />,
        children: [
            //Rotas privadas
            {
                path: '/',
                element: <PrivateRoute />,
                children: [
                    {
                        path: '/',
                        element: <InternalLayout />,
                        children: [
                            {
                                path: 'dashboard',
                                element: (
                                    <Breadcrumb>
                                        <DashBoard />
                                    </Breadcrumb>
                                ),
                                handle: { crumb: { title: 'DASHBOARD' } },
                            },
                            {
                                path: 'profile',
                                element: (
                                    <Breadcrumb>
                                        <Profile />
                                    </Breadcrumb>
                                ),
                                handle: { crumb: { title: 'MY_PROFILE' } },
                            },
                            {
                                path: 'users',
                                handle: { crumb: { title: 'USERS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <PermissionedRoute routePermissions={EUserTypes.ADMIN}>
                                                    <ListUsers />
                                                </PermissionedRoute>
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: ':userId',
                                        element: (
                                            <Breadcrumb>
                                                <PermissionedRoute routePermissions={EUserTypes.ADMIN}>
                                                    <UserDetails />
                                                </PermissionedRoute>
                                            </Breadcrumb>
                                        ),
                                        handle: { crumb: { title: 'USER_DETAILS', params: ['userId'] } },
                                    },
                                ],
                            },
                            {
                                path: 'client-users',
                                handle: { crumb: { title: 'CLIENT_USERS_MENU' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <ListClientUsers />
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: ':clientUserId',
                                        element: (
                                            <Breadcrumb>
                                                <ClientUserDetails />
                                            </Breadcrumb>
                                        ),
                                        handle: { crumb: { title: 'CLIENT_USER_DETAILS', params: ['clientUserId'] } },
                                    },
                                ],
                            },
                            {
                                path: 'clients',
                                handle: { crumb: { title: 'CLIENTS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <ListClients />
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: ':clientId',
                                        handle: {
                                            crumb: {
                                                title: 'CLIENT_DETAILS',
                                                params: ['clientId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <ClientDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                            {
                                                path: 'work-sector/:workSectorId',
                                                handle: {
                                                    crumb: {
                                                        title: 'WORK_SECTOR_DETAILS',
                                                        params: ['workSectorId'],
                                                    },
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <Breadcrumb>
                                                                <WorkSectorDetails />
                                                            </Breadcrumb>
                                                        ),
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'client-user/:clientUserId',
                                                handle: {
                                                    crumb: {
                                                        title: 'BOND_DETAILS',
                                                        params: ['clientUserId'],
                                                    },
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <Breadcrumb>
                                                                <ClientUser />
                                                            </Breadcrumb>
                                                        ),
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'client-address/:addressId',
                                                handle: {
                                                    crumb: {
                                                        title: 'CLIENT_ADDRESSES',
                                                        params: ['addressId'],
                                                    },
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <Breadcrumb>
                                                                <ClientAddressDetails />
                                                            </Breadcrumb>
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'probability-x-gravity',
                                handle: { crumb: { title: 'PROBABILITY_X_GRAVITY' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <ProbabilityXGravity />
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: 'control-mesure/:controlMesureId',
                                        handle: {
                                            crumb: {
                                                title: 'CONTROL_MESURES',
                                                params: ['controlMesureId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <ControlMesureDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'frequency/:frequencyId',
                                        handle: {
                                            crumb: {
                                                title: 'FREQUENCIES',
                                                params: ['frequencyId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <FrequenciesDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'detection/:detectionId',
                                        handle: {
                                            crumb: {
                                                title: 'DETECTIONS',
                                                params: ['detectionId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <DetectionDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'severity/:severityId',
                                        handle: {
                                            crumb: {
                                                title: 'SEVERITIES',
                                                params: ['severityId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <SeveritiesDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'coverage-scale/:coverageScaleId',
                                        handle: {
                                            crumb: {
                                                title: 'COVERAGE_SCALES',
                                                params: ['coverageScaleId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <CoverageScalesDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'priority-level/:priorityLevelId',
                                        handle: {
                                            crumb: {
                                                title: 'PRIORITY_LEVELS',
                                                params: ['priorityLevelId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <PriorityLevelsDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'settings',
                                handle: { crumb: { title: 'SETTINGS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <Settings />
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: 'report-config/create',
                                        handle: { crumb: { title: 'REPORT_CONFIG_CREATION' } },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <CreateReportConfig />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'report-config/:reportConfigId',
                                        handle: {
                                            crumb: {
                                                title: 'REPORT_CONFIG_DETAILS',
                                                params: ['reportConfigId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <ReportConfigDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'risks',
                                handle: { crumb: { title: 'RISKS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <Risks />
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: 'risk/:riskId',
                                        handle: {
                                            crumb: {
                                                title: 'RISK_DETAILS',
                                                params: ['riskId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <RiskDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'normative/:normativeId',
                                        handle: {
                                            crumb: {
                                                title: 'NORMATIVE_DETAILS',
                                                params: ['normativeId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <NormativeDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'recommendation/:recommendationId',
                                        handle: {
                                            crumb: {
                                                title: 'RECOMMENDATION_DETAILS',
                                                params: ['recommendationId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <RecommendationsDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'strength/:strengthId',
                                        handle: {
                                            crumb: {
                                                title: 'STRENGTH_DETAILS',
                                                params: ['strengthId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <StrengthDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'affected-pillar/:affectedPillarId',
                                        handle: {
                                            crumb: {
                                                title: 'AFFECTED_PILLAR_DETAILS',
                                                params: ['affectedPillarId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <AffectedPillarsDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'insurance/:insuranceId',
                                        handle: {
                                            crumb: {
                                                title: 'INSURANCE_COMPANY_DETAILS',
                                                params: ['insuranceId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <InsuranceCompanyDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'reports',
                                handle: { crumb: { title: 'REPORTS' } },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <Reports />
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: ':reportId',
                                        handle: {
                                            crumb: {
                                                title: 'REPORT',
                                                params: ['reportId'],
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <ReportDetails />
                                                    </Breadcrumb>
                                                ),
                                            },
                                            {
                                                path: 'follow-up/:followUpId',
                                                handle: {
                                                    crumb: {
                                                        title: 'FOLLOW_UP',
                                                        params: ['followUpId'],
                                                    },
                                                },
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <Breadcrumb>
                                                                <FollowUpDetails />
                                                            </Breadcrumb>
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'change-password',
                        element: (
                            <ExternalLayout>
                                <ChangePassword />
                            </ExternalLayout>
                        ),
                    },
                ],
            },

            //Rotas públicas
            {
                path: 'login',
                element: <ExternalLayout />,
                children: [
                    {
                        index: true,
                        element: <Login />,
                    },
                    {
                        path: 'reset-password/:token',
                        element: <ResetPasswordForm />,
                    },
                ],
            },
        ],
    },
])

export function Router() {
    return <RouterProvider router={router} />
}
